import { MouseEventHandler, ReactElement } from "react";
import classnames from "classnames";

interface IButton {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className: string;
}

interface ITopbarSidebarButton {
  onClickDesktop: MouseEventHandler<HTMLButtonElement>;
  onClickMobile: MouseEventHandler<HTMLButtonElement>;
}

const icon = `${process.env.PUBLIC_URL}/img/burger.svg`;

const Button = ({ onClick, className }: IButton) => (
  <button
    onClick={onClick}
    className={classnames("topbar__button", className)}
    type="button"
  >
    <img src={icon} alt="" className="topbar__button-icon" />
  </button>
);

const TopbarSidebarButton = ({
  onClickDesktop,
  onClickMobile,
}: ITopbarSidebarButton): ReactElement => (
  <div>
    <Button onClick={onClickDesktop} className="topbar__button--desktop" />
    <Button onClick={onClickMobile} className="topbar__button--mobile" />
  </div>
);

export default TopbarSidebarButton;

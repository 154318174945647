import { useTranslation } from "react-i18next";
import { Field, Form } from "react-final-form";
import { Link, generatePath } from "react-router-dom";
import { Button, ButtonToolbar } from "reactstrap";
import createUserValidate from "@validators/Users/CreateUser.validate";
import styles from "../CreateUsers/CreateUser.module.scss";
import Loader from "@components/Loader";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import WalletOutline from "mdi-react/WalletOutlineIcon";
import FormField from "@easydev//form/FormField";
import { USERS } from "@constants/Routes";
import renderSelectField from "@easydev/form/Select";
import { IUser } from "@interfaces/Users/Users";
import useRoles from "@mappers/Users/UserRole.mapper";
import UserContext from "@context/UserContext/UserContext";
import { TUserContext } from "@userContext";
import { useContext } from "react";
import { UserRole } from "@enums/UserRole";

interface IModifyUserForm {
  setFormValues: any;
  handleModalStatus: () => void;
  isLoading: boolean;
  projectId: string;
  userToModify: IUser | undefined;
}

const ModifyUserForm = ({
  setFormValues,
  handleModalStatus,
  isLoading,
  projectId,
  userToModify,
}: IModifyUserForm) => {
  const { t } = useTranslation(["core"]);
  const { GetRole, GetRoles } = useRoles();
  const initialStateValue = userToModify && GetRole(userToModify.role);
  const { currentUser } = useContext<TUserContext>(UserContext);

  let roleDisabled = false;
  if (
    userToModify?.address === currentUser?.walletAddress ||
    userToModify?.role === UserRole.Owner
  ) {
    roleDisabled = true;
  }

  return (
    <Form
      onSubmit={(FormValues) => {
        setFormValues(FormValues);
        handleModalStatus();
      }}
      validate={createUserValidate}
    >
      {({ handleSubmit, form }) => {
        return (
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("core:common.name")}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="name"
                  component={FormField as any}
                  type="text"
                  placeholder={`${t("core:common.client")} ${t(
                    "core:common.name"
                  )}`}
                  initialValue={userToModify?.name}
                  isAboveError
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("core:common.wallet")}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <WalletOutline />
                </div>
                <Field
                  name="address"
                  component={FormField as any}
                  type="text"
                  placeholder="0x...XYZ"
                  initialValue={userToModify?.address}
                  isAboveError
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("core:common.role")}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="role"
                  component={renderSelectField}
                  type="text"
                  placeholder={`${t("core:common.select_placeholder")} ${t(
                    "core:common.role"
                  )}`}
                  options={GetRoles(userToModify?.role === UserRole.Owner)}
                  initialValue={initialStateValue}
                  isAboveError
                  disabled={roleDisabled}
                />
              </div>
            </div>

            <ButtonToolbar
              className={`form__button-toolbar ${styles.button__toolbar} ${
                isLoading && `${styles.loader}`
              }`}
            >
              {isLoading ? (
                <Loader isInsideThing />
              ) : (
                <>
                  <Link to={generatePath(USERS, { projectId })}>
                    <Button type="button" onClick={form.reset}>
                      <span className={`lnr lnr-chevron-left ${styles.lnr}`} />
                      {t("core:common.goback")}
                    </Button>
                  </Link>
                  <Button color="primary" className="btn-warning">
                    <span className="lnr lnr-pencil" />{" "}
                    {t("core:common.modify")}
                  </Button>
                </>
              )}
            </ButtonToolbar>
          </form>
        );
      }}
    </Form>
  );
};

export default ModifyUserForm;

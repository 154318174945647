import { MediaType } from "@enums/mediaType";
import { ArtUploadStep } from "@enums/artUploadStep";

export const stepFromMediaType = (mediaType: MediaType): ArtUploadStep => {
  let step = ArtUploadStep.SELECT_ASSET;

  if (mediaType === MediaType.video || mediaType === MediaType.image) {
    step = ArtUploadStep.EDIT_MEDIA_ASSET;
  } else if (mediaType === MediaType.text) {
    step = ArtUploadStep.EDIT_NAMEPLATE_ASSET;
  }

  return step;
};

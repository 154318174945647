import { Card, CardBody, Col, Row } from "reactstrap";
import MediaShowHideToolbar from "./components/MediaShowHideToolbar";
import styles from "./ContainerCards.module.scss";

interface IContainerCards {
  titleName?: string;
  isLoading: boolean;
  children?: React.ReactNode;
  isPlaceCard?: boolean;
}

const ContainerCards = ({
  titleName,
  isLoading,
  children,
  isPlaceCard,
}: IContainerCards) => {
  return (
    <Row className="table-container">
      {!isLoading && (
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              {isPlaceCard && <MediaShowHideToolbar />}
              <Col md={12} xl={12} lg={12} xs={12}>
                {titleName && (
                  <div className="card__title">
                    <h5 className="bold-text">{titleName}</h5>
                  </div>
                )}

                <div className={styles.container__cards}>{children}</div>
              </Col>
            </CardBody>
          </Card>
        </Col>
      )}
    </Row>
  );
};

export default ContainerCards;

import { IMAGE_MAX, VIDEO_FORMAT, VIDEO_MAX } from "@constants/Config";
import { formatSizeUnits } from "@helpers/formatSizeUnits";
import { t } from "i18next";

export const validateSize = (file: File) => {
  const fileSize = formatSizeUnits(file.size);

  if (file.type === VIDEO_FORMAT) {
    return file.size > VIDEO_MAX
      ? `${t("core:validators:video-too-big")}` + fileSize
      : undefined;
  }

  return file.size > IMAGE_MAX
    ? `${t("core:validators:image-too-big")}` + fileSize
    : undefined;
};

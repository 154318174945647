import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { MutableState, Tools } from "final-form";

const setFieldValueMutator = {
  setValue: (
    args: any[],
    state: MutableState<IArtUploader, IArtUploader>,
    { changeValue }: Tools<IArtUploader, IArtUploader>
  ) => {
    changeValue(state, args[0], () => args[1]);
  },
};

export default setFieldValueMutator;

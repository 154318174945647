import { ArtUploadStep } from "@enums/artUploadStep";
import { MediaType } from "@enums/mediaType";

export const defaultArtUploader = {
  dropzone: [],
  showFrame: true,
  externalLink: "",
  onclick: "",
  nameplateLink: null,
  nameplateImageUrl: null,
  thumbnail: [],
  mediaType: MediaType.none,
  step: ArtUploadStep.SELECT_ASSET,
};

import { useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import AccountPlusOutlineIcon from "mdi-react/AccountPlusOutlineIcon";
import { useTranslation } from "react-i18next";
import CreateUserForm from "./CreateUserForm";
import createUser from "@services/Users/createUser";
import { IUser } from "@interfaces/Users/Users";
import createUserMapper from "@mappers/Users/CreateUser.mapper";
import { showNotification } from "@components/EasyDev/Notification";
import Title from "@components/Title";
import { USERS } from "@constants/Routes";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import NotAccess401 from "@pages/NotAccess401";
import { ResourcesAccess } from "@enums/ResourcesAccess";

const CreateUser = () => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { projectId } = useParams<{
    projectId: string;
  }>();

  if (!IsEnabledResource(ResourcesAccess.OWNER_ADMIN)) {
    return <NotAccess401 />;
  }

  const handleCreateUser = (FormValues: IUser, id: string) => {
    setIsLoading(true);
    const userFormatted = createUserMapper(FormValues);
    createUser(id, userFormatted)
      .then(async (result) => {
        showNotification(
          `${t("core:common.user")}`,
          t("core:notifications.create_success_message"),
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          `${t("core:common.user")}`,
          `${t("core:notifications.err_message")} ${FormValues.name}`,
          "danger"
        );
      })
      .finally(() => {
        setIsLoading(false);
        history.push(generatePath(USERS, { projectId }));
      });
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title
            title={`${t("core:common.users")} | ${t("core:common.create")}`}
            children={<AccountPlusOutlineIcon />}
            isSidebar
            isMdiReactIcon
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={8}>
          <Card>
            <CardBody>
              <CreateUserForm
                handleCreateUser={handleCreateUser}
                isLoading={isLoading}
                projectId={projectId}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateUser;

import twitter_icon from "@images/socialMedia/social_twitter.png";
import instagram_icon from "@images/socialMedia/social_instagram.png";
import medium_icon from "@images/socialMedia/social_medium.png";
import linkedin_icon from "@images/socialMedia/social_linkedin.png";
import twitter_hover_icon from "@images/socialMedia/social_twitter_hover.png";
import instagram_hover_icon from "@images/socialMedia/social_instagram_hover.png";
import medium_hover_icon from "@images/socialMedia/social_medium_hover.png";
import linkedin_hover_icon from "@images/socialMedia/social_linkedin_hover.png";
import {
  SOCIAL_TWITTER,
  SOCIAL_LINKEDIN,
  SOCIAL_INSTAGRAM,
  SOCIAL_MEDIUM,
} from "@constants/socialLinks";

interface ISocialMediaData {
  id: string;
  name: string;
  icon: string;
  icon_hover: string;
  url: string;
  order: number;
  state: boolean;
}

export const dataSocial: ISocialMediaData[] = [
  {
    id: "1",
    name: "Twitter",
    icon: twitter_icon,
    icon_hover: twitter_hover_icon,
    url: SOCIAL_TWITTER,
    order: 1,
    state: true,
  },
  {
    id: "2",
    name: "Instagram",
    icon: instagram_icon,
    icon_hover: instagram_hover_icon,
    url: SOCIAL_INSTAGRAM,
    order: 2,
    state: true,
  },
  {
    id: "3",
    name: "Linkedin",
    icon: linkedin_icon,
    icon_hover: linkedin_hover_icon,
    url: SOCIAL_LINKEDIN,
    order: 3,
    state: true,
  },
  {
    id: "4",
    name: "Medium",
    icon: medium_icon,
    icon_hover: medium_hover_icon,
    url: SOCIAL_MEDIUM,
    order: 4,
    state: true,
  },
];

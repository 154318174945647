import emptyFieldValidate from "@validators/shared/empty.validate";
import { isValidURL } from "@validators/shared/url.validate";
import { t } from "i18next";

export type TErrors = {
  nameplateLink: string | undefined;
  nameplateText: string | undefined;
};

const editTextValidations = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    nameplateLink: undefined,
    nameplateText: undefined,
  };

  errors.nameplateText = emptyFieldValidate(
    FormValues.nameplateText,
    t("artuploader:nameplate_text")
  );

  errors.nameplateLink = isValidURL(
    FormValues.nameplateLink,
    t("artuploader:nameplate_link")
  );

  return errors;
};

export default editTextValidations;

import { isValidURL } from "@validators/shared/url.validate";
import { t } from "i18next";

export type TErrors = {
  defaultImage: string | undefined;
  defaultLink: string | undefined;
};

const settingsValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    defaultImage: undefined,
    defaultLink: undefined,
  };

  errors.defaultImage = isValidURL(
    FormValues.defaultImage,
    t("core:common.default_image")
  );

  errors.defaultLink = isValidURL(
    FormValues.defaultLink,
    t("core:common.default_link")
  );

  return errors;
};

export default settingsValidate;

import { UserRole } from "@enums/UserRole";
import { ISelect } from "@interfaces/Shared/select";

interface IUseRoles {
  GetRole: (value: string) => ISelect;
  GetRoles: (withOwner: boolean) => ISelect[];
}

const useRole = (): IUseRoles => {
  const GetRole = (value: string) => {
    return GetRoles(true).filter((p) => p.value === value)[0];
  };

  const GetRoles = (withOwner: boolean) => {
    let roles = [
      { label: UserRole.Admin, value: UserRole.Admin },
      { label: UserRole.Collaborator, value: UserRole.Collaborator },
    ];

    withOwner && roles.push({ label: UserRole.Owner, value: UserRole.Owner });

    return roles;
  };

  return { GetRole, GetRoles };
};

export default useRole;

import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

export const urlFieldValidate = (
  field: string,
  name: string,
  validateEmptyField: boolean = false
) => {
  try {
    if (field) {
      Boolean(new URL(field));
    }

    let result = validateEmptyField
      ? emptyFieldValidate(field, name)
      : undefined;

    return result;
  } catch (e) {
    return `${name} ${t("core:validators:valid-url")}`;
  }
};

export const isValidURL = (url: string, name: string) => {
  if (!url) {
    return undefined;
  }

  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  );

  if (!res) {
    return `${name} ${t("core:validators:valid-url")}`;
  }
};

export const isOpenSeaURL = (url: string) => {
  return url.match(
    "^https://opensea.io/([a-z]{2}(-[A-Z]{2})?/)?assets/[a-zA-Z]+/0x[A-Fa-f0-9]{40}/[0-9]+$"
  );
};

export const isValidOpenSeaURL = (url: string) => {
  const res = url.match(
    "^https://opensea.io/([a-z]{2}(-[A-Z]{2})?/)?assets/[a-zA-Z]+/0x[A-Fa-f0-9]{40}/[0-9]+$"
  );

  if (!res) {
    return `${t("core:validators:link-opensea-wrong")}`;
  }
};

export const isValidOpenSeaChain = (
  url: string,
  onlyChain: string = "ethereum"
) => {
  const urlSplitted = url.split("/");
  const chainUrl = urlSplitted[urlSplitted.length - 3];

  if (chainUrl !== onlyChain) {
    return `${t("core:validators:chain-opensea-wrong")}`.replace(
      "XXX",
      chainUrl
    );
  }
};

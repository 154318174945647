export const API_URL = process.env.REACT_APP_API_URL as string;
export const METADATA_SERVICES_URL = process.env
  .REACT_APP_METADATA_SERVICES_URL as string;
export const KEY_INFURA_ID = process.env.REACT_APP_INFURA_ID as string;
export const KEY_WALLET_CONNECT_CHAIN = parseInt(
  process.env.REACT_APP_WALLET_CONNECT_CHAIN ?? ""
);
export const KEY_WALLET_CONNECT_ID = process.env
  .REACT_APP_WALLET_CONNECT_ID as string;

export const KEY_APPNAME = "Voxel Architects - CMS";
export const KEY_STORE_I18N = "voxelarchitects_backoffice_i18n";
export const KEY_STORE_USER = "voxelarchitects_backoffice_user";
export const KEY_CURRENT_PROJECT = "voxelarchitects_backoffice_currentProject";
export const KEY_APPLOGO = "https://myapp.com/mylogo.png";

import apiInstance from "../Shared/instanceAxios";

const getProjects = () =>
  apiInstance({
    url: "/projects/",
    params: {
      state: true,
    },
  });

export default getProjects;

import emptyFieldValidate from "@validators/shared/empty.validate";
import userNameValidate from "@validators/shared/userName.validate";
import walletAddressValidate from "@validators/shared/walletAddressValidate";
import { t } from "i18next";

export type TErrors = {
  name: string | undefined;
  address: string | undefined;
  role: string | undefined;
};

const createUserValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    name: undefined,
    address: undefined,
    role: undefined,
  };

  errors.name = userNameValidate(FormValues.name);

  errors.address = walletAddressValidate(FormValues.address);

  errors.role = emptyFieldValidate(FormValues.role, t("core:common:role"));

  return errors;
};

export default createUserValidate;

import { ReactElement } from "react";
import styles from "./SocialMedia.module.scss";
import { dataSocial } from "./SocialMedia.data";

const SocialMedia = (): ReactElement => (
  <div className={styles.socialMedia}>
    {dataSocial
      .filter((social) => social.state)
      .map((social) => {
        return (
          <a
            key={social.id}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            title={social.name}
          >
            <img
              onMouseOver={(e): void => {
                e.currentTarget.src = social.icon_hover;
              }}
              onMouseOut={(e): void => {
                e.currentTarget.src = social.icon;
              }}
              src={social.icon}
              alt={social.name}
            />
          </a>
        );
      })}
  </div>
);

export default SocialMedia;

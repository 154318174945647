import { ReactElement } from "react";
import Switch from "@components/Switch";
import styles from "./Header.module.scss";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import { useTranslation } from "react-i18next";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { FormApi } from "final-form";

interface IHeader {
  title: string;
  subtitle?: string;
  showSwitch?: boolean;
  description?: string;
  isOpen: boolean;
  form?: FormApi<IArtUploader, Partial<IArtUploader>>;
  handleConfirm: () => void;
  handleCancel: () => void;
}

const Header = ({
  title,
  subtitle,
  showSwitch,
  description,
  isOpen,
  form,
  handleConfirm,
  handleCancel,
}: IHeader): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={`modal__header ${styles.header}`}>
      <div className={styles.headerContent}>
        <div className={styles.mainText}>
          <h4
            className={`text-modal modal__title ${styles.bolded} ${styles.uploadText}`}
          >
            {title}
          </h4>
          {subtitle && (
            <h5 className={`text-modal ${styles.bolded} ${styles.uploadText}`}>
              {subtitle}
            </h5>
          )}
        </div>
        {showSwitch && (
          <>
            <span className={styles.showFrame}>
              {t("artuploader:show_frame")}
            </span>
            <Switch
              name="showFrame"
              leftText={t("artuploader:hide")}
              rightText={t("artuploader:show")}
              form={form}
            />
          </>
        )}
      </div>
      {description && (
        <h6
          className={`text-modal ${styles.uploadText} ${styles.marginBottom}`}
        >
          {description}
        </h6>
      )}

      <ModalConfirmation
        title={t("artuploader:hide_frame_title")}
        body={t("artuploader:hide_frame_body")}
        modalStatus={isOpen}
        handleModalStatus={handleCancel}
        handleConfirm={handleConfirm}
        modalType="warning"
        icon="pencil"
      />
    </div>
  );
};

export default Header;

import { t } from "i18next";
import { ReactElement } from "react";
import { Button, ButtonToolbar, Modal } from "reactstrap";

interface IModalConfirmation {
  title: string;
  icon: string;
  body: string;
  modalStatus: boolean;
  handleModalStatus: () => void;
  handleConfirm: () => void;
  modalType: "warning" | "danger";
  isInLogin?: boolean;
}

const ModalConfirmation = ({
  title,
  icon,
  body,
  modalStatus,
  handleModalStatus,
  handleConfirm,
  modalType,
  isInLogin,
}: IModalConfirmation): ReactElement => (
  <Modal isOpen={modalStatus} className={`modal-dialog--${modalType}`}>
    <div className="modal__header">
      <button
        className="lnr lnr-cross modal__close-btn"
        aria-label="modal__close-btn"
        type="button"
        onClick={handleModalStatus}
      />
      <h4 className="text-modal modal__title">
        <span className={`lnr lnr-${icon} modal__title-icon`} /> {title}
      </h4>
    </div>
    <div className="modal__body">{body}</div>
    <ButtonToolbar className="modal__footer">
      {!isInLogin && (
        <Button className="modal_cancel" onClick={handleModalStatus}>
          {`${t("core:common.cancel")}`}
        </Button>
      )}
      <Button className="modal_ok" color={modalType} onClick={handleConfirm}>
        Ok
      </Button>
    </ButtonToolbar>
  </Modal>
);

export default ModalConfirmation;

export const HOME = "/";
export const LOGIN = "/login";
export const PROJECTS = "/projects";
export const PROJECTS_DETAIL = `${PROJECTS}/:projectId`;
export const ERROR401 = `${PROJECTS_DETAIL}/401`;
export const SPACE_DETAIL = `${PROJECTS_DETAIL}/space/:spaceId`;
export const PLACE_DETAIL = `${SPACE_DETAIL}/place/:placeId`;
export const MEDIA_UPLOADER = `${PLACE_DETAIL}/media/:mediaId`;
export const USERS = `${PROJECTS_DETAIL}/users`;
export const USERS_CREATE = `${USERS}/create`;
export const USERS_DETAIL = `${USERS}/:userId/detail`;
export const USERS_MODIFY = `${USERS}/:userId/modify`;
export const GATINGSYSTEM = `${PROJECTS_DETAIL}/gatingsystem`;
export const SETTINGS = `${PROJECTS_DETAIL}/settings`;

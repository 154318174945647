import { Row } from "reactstrap";
import DataReactTable from "../DataReactTable";

const Table = ({ columns, items }) => {
  if (items) {
    return (
      <Row className="table-container">
        <DataReactTable
          reactTableData={{
            tableHeaderData: columns,
            tableRowsData: items
          }}
          withPagination
          manualPageSize={[10, 20, 30, 40]}
        />
      </Row>
    );
  }
  return <div></div>;
};

export default Table;

import { validateSize } from "@validators/shared/fileSize.validator";
import { urlFieldValidate } from "@validators/shared/url.validate";

export type TErrors = {
  dropzone: string | undefined;
  externalLink: string | undefined;
};

const fileUploadValidations = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    dropzone: undefined,
    externalLink: undefined,
  };

  if (FormValues.dropzone.length > 0) {
    errors.dropzone = validateSize(FormValues.dropzone[0]);
  }

  if (!FormValues.dropzone.length) {
    errors.externalLink = urlFieldValidate(
      FormValues.externalLink,
      "External Link"
    );
  }

  return errors;
};

export default fileUploadValidations;

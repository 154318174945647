import { INftUserDto } from "@interfaces/GatingSystem/NftUserDto";
import apiInstance from "@services/Shared/instanceAxios";

const updateNFTCollectors = (projectId: string, editDto: INftUserDto) =>
  apiInstance({
    url: `/projects/${projectId}/gating-system/contract`,
    method: "put",
    data: editDto,
  });

export default updateNFTCollectors;

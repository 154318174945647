import { useContext, useEffect, useState } from "react";
import UserContext from "@context/UserContext/UserContext";
import { Col, Container, Row } from "reactstrap";
import ContainerCards from "@components/ContainerCards";
import useGetSpaces from "@hooks/Spaces/useGetSpaces";
import Title from "@components/Title";
import { useParams } from "react-router-dom";
import Loader from "@components/Loader";
import useGetProject from "@hooks/Project/useGetProject";
import { useTranslation } from "react-i18next";
import PlaceCard from "@components/ContainerCards/components/PlaceCard";
import localStorageServices from "@utils/localStorage.services";
import NotFound404 from "@pages/NotFound404";

const ProjectDetail = () => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(true);
  const { setCurrentProject } = useContext(UserContext);
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const { loading: isLoadingProject, data: responseProject } =
    useGetProject(projectId);
  const { loading: isLoadingSpaces, data: responseSpaces } =
    useGetSpaces(projectId);

  // trigger on component mount
  useEffect(() => {
    if (responseProject && responseSpaces) {
      const currentFullProject = {
        project: responseProject,
        spaces: responseSpaces.spaces,
      };
      localStorageServices.setCurrentFullProject(currentFullProject);
      setCurrentProject(currentFullProject);
    }
    setLoading(false);
  }, [responseProject, responseSpaces, setCurrentProject]);

  if (isLoadingProject || isLoadingSpaces) {
    return <Loader />;
  }

  if (!loading && !responseProject && !responseSpaces) {
    return <NotFound404 />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title title={t("core:common.spaces")} icon="apartment" />
        </Col>
      </Row>

      {responseSpaces?.spaces?.map((space) => (
        <ContainerCards
          key={space.name}
          titleName={space.name}
          isLoading={isLoadingSpaces}
        >
          {space.places?.map((place) => (
            <PlaceCard key={place.name} space={space} place={place} />
          ))}
        </ContainerCards>
      ))}
    </Container>
  );
};

export default ProjectDetail;

import { useEffect, useState } from "react";
import { useWeb3Context, WalletProviders } from "@nftstudios/web3-provider";
import localStorageServices from "@utils/localStorage.services";
import { ICurrentUser } from "@interfaces/Account/currentUser";
import { TUserContext } from "@userContext";
import UserContext from "./UserContext";
import getProjects from "@services/Projects/getProjects";
import getMessageToSign from "@services/Web3/getMessageToSign";
import { IProject } from "@interfaces/Projects/Project";
import { IFullProject } from "@interfaces/Projects/FullProject";
import { showNotification } from "@components/EasyDev/Notification";
import { t } from "i18next";
import { KEY_STORE_USER } from "@constants/Env";
import getSuperuser from "@services/Superusers/getSuperuser";
import { SuperuserRole } from "@enums/SuperuserRole";

type ProviderProps = {
  children: React.ReactNode;
};

export const UserContextProvider = ({ children }: ProviderProps) => {
  const [modalStatus, setModalStatus] = useState(false);
  const { connect, signMessage, walletAddress, isInitialized, disconnect } =
    useWeb3Context();
  const currentLocalUser = localStorageServices.getLocalUserStorage();
  const currentLocalFullProject = localStorageServices.getCurrentFullProject();
  const [currentUser, setCurrentUser] = useState<ICurrentUser | null>(
    currentLocalUser ?? null
  );
  const [currentProject, setCurrentProject] = useState<IFullProject | null>(
    currentLocalFullProject ?? null
  );
  const [authenticated, setAuthenticated] = useState(
    currentLocalUser ? true : false
  );

  useEffect(() => {
    if (walletAddress !== "" && isInitialized && !authenticated) {
      handleConnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, isInitialized]);

  const handleConnect = async () => {
    if (isInitialized && !walletAddress) {
      connect(
        window.ethereum
          ? WalletProviders.MetaMask
          : WalletProviders.WalletConnectV2
      );
    }

    if (!authenticated) {
      const messageToSign = await getMessage();
      const signature = await signMessage(messageToSign);

      let user: ICurrentUser = {
        walletAddress,
        signature,
        isSuperAdmin: false,
      };

      localStorageServices.setLocalUserStorage(user);

      handleLogin(user);
    }
  };

  const handleLogin = async (user: ICurrentUser) => {
    await getProjects().then(async (response: { data: IProject[] }) => {
      if (response.data?.length === 0) {
        handleModalStatus();
        setAuthenticated(false);
        localStorageServices.removeLocalStorage(KEY_STORE_USER);
        setCurrentUser(null);
        showNotification(
          "",
          `${t("core:notifications.wallet-without-projects")} `,
          "danger",
          true
        );
        return;
      } else {
        user.isSuperAdmin = await isSuperUser(user.walletAddress);
        user.projects = response.data;
        localStorageServices.setLocalUserStorage(user);
        setAuthenticated(true);
        setCurrentUser(user);
      }
    });
  };

  const isSuperUser = async (walletAddress: string): Promise<boolean> => {
    const superUser = (await getSuperuser(walletAddress).catch(() => null))
      ?.data;
    return !!superUser && superUser.roles.includes(SuperuserRole.RoleAdmin);
  };

  const getMessage = async (): Promise<string> => {
    const { data: response } = await getMessageToSign();

    return response.message;
  };

  const handleLogout = () => {
    handleModalStatus();
    localStorageServices.removeLocalStorage(KEY_STORE_USER);
    setCurrentUser(null);
    setAuthenticated(false);
    disconnect();
    window.location.reload();
  };

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  const stateValues: TUserContext = {
    authenticated,
    currentUser,
    currentProject,
    handleConnect,
    handleLogout,
    setCurrentProject,
    handleModalStatus,
    modalStatus,
  };

  return (
    <UserContext.Provider value={stateValues}>{children}</UserContext.Provider>
  );
};

import { validateSize } from "@validators/shared/fileSize.validator";
import { validateType } from "@validators/shared/fileType.validator";

export const validateImage = (file: File) => {
  let message = validateSize(file);

  if (!message) {
    message = validateType("image", file);
  }

  return message;
};

import { IShowHideAllMedia } from "@interfaces/Media/ShowHideAllMedia";
import apiInstance from "@services/Shared/instanceAxios";

const showHideAllMedia = (projectId: string, data: IShowHideAllMedia) =>
  apiInstance({
    url: `/media/${projectId}/all-states`,
    method: "put",
    data: data,
  });

export default showHideAllMedia;

import { Field, Form } from "react-final-form";
import { Link, generatePath } from "react-router-dom";
import { Button, ButtonToolbar } from "reactstrap";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import WalletOutline from "mdi-react/WalletOutlineIcon";
import FormField from "@easydev/form/FormField";
import { useTranslation } from "react-i18next";
import { USERS } from "@constants/Routes";
import createUserValidate from "@validators/Users/CreateUser.validate";
import styles from "./CreateUser.module.scss";
import Loader from "@components/Loader";
import renderSelectField from "@easydev/form/Select";
import useRoles from "@mappers/Users/UserRole.mapper";
import { useContext } from "react";
import UserContext from "@context/UserContext/UserContext";
import { TUserContext } from "@userContext";
import { UserRole } from "@enums/UserRole";

interface ICreateUserForm {
  handleCreateUser: any;
  isLoading: boolean;
  projectId: string;
}

const CreateUserForm = ({
  handleCreateUser,
  isLoading,
  projectId,
}: ICreateUserForm) => {
  const { t } = useTranslation("common");
  const { GetRoles } = useRoles();
  const { currentUser, currentProject } = useContext<TUserContext>(UserContext);

  const isOwnerUser =
    (currentProject?.project.users?.some(
      (user) =>
        user.role === UserRole.Owner &&
        user.address === currentUser?.walletAddress
    ) ??
      false) ||
    (currentUser?.isSuperAdmin ?? false);

  return (
    <Form
      onSubmit={(FormValues) => {
        handleCreateUser(FormValues, projectId);
      }}
      validate={createUserValidate}
    >
      {({ handleSubmit, form }) => (
        <form className="form form--horizontal" onSubmit={handleSubmit}>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.name")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="name"
                component={FormField as any}
                type="text"
                placeholder="John Doe"
                isAboveError
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.wallet")}
            </span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <WalletOutline />
              </div>
              <Field
                name="address"
                component={FormField as any}
                type="text"
                placeholder="0x...XYZ"
                isAboveError
              />
            </div>
          </div>

          <div className="form__form-group">
            <span className="form__form-group-label">
              {t("core:common.role")}
            </span>
            <div className="form__form-group-field">
              <Field
                name="role"
                component={renderSelectField}
                type="text"
                placeholder={`${t("core:common.select_placeholder")} ${t(
                  "core:common.role"
                )}`}
                options={GetRoles(isOwnerUser)}
                isAboveError
              />
            </div>
          </div>

          <ButtonToolbar
            className={`form__button-toolbar ${styles.button__toolbar} ${
              isLoading && `${styles.loader}`
            }`}
          >
            {isLoading ? (
              <Loader isInsideThing />
            ) : (
              <>
                <Link to={generatePath(USERS, { projectId })}>
                  <Button type="button" onClick={form.reset}>
                    <span className={`lnr lnr-chevron-left ${styles.lnr}`} />
                    {t("core:common.goback")}
                  </Button>
                </Link>
                <Button color="primary" type="submit">
                  <span className={`lnr lnr-plus-circle ${styles.lnr}`} />
                  {t("core:common.create")}
                </Button>
              </>
            )}
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

export default CreateUserForm;

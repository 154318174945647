import { ReactElement } from "react";
import { NFTSTUDIOS_URI } from "@constants/Config";
import CompanySign from "./components/CompanySign";
import website_logo from "@images/footer/logo_website.png";
import nftstudios_logo from "@images/companySign/nftstudios-logo.png";
import SocialMedia from "./components/SocialMedia";
import HorizontalLine from "./components/HorizontalLine";
import styles from "./Footer.module.scss";
import { HOME } from "@constants/Routes";

const Footer = (): ReactElement => {
  const fullYear = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className={styles.footerBody}>
        <div className={styles.footerLine}>
          <a href={HOME}>
            <img
              src={website_logo}
              className={styles.footerLogo}
              height="20"
              alt="Voxel Architects logo"
            />
          </a>

          <SocialMedia />
        </div>
        <HorizontalLine />
        <div className={styles.footerLine}>
          <p>©{fullYear} VoxelArchitects. All rights reserved.</p>
          <CompanySign
            name="NftStudios"
            url={NFTSTUDIOS_URI}
            logo={nftstudios_logo}
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { t } from "i18next";
import { isValidURL } from "@utils/validators/shared/url.validate";
import { validateSize } from "@validators/shared/fileSize.validator";

export type TErrors = {
  onclick: string | undefined;
  thumbnail: string | undefined;
};

const editVideoValidations = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    onclick: undefined,
    thumbnail: undefined,
  };

  errors.onclick = isValidURL(
    FormValues.onclick,
    t("artuploader:onclick_link")
  );

  if (FormValues.thumbnail.length > 0) {
    errors.thumbnail = validateSize(FormValues.thumbnail[0]);
  }

  return errors;
};

export default editVideoValidations;

import { useTranslation } from "react-i18next";
import styles from "./Title.module.scss";
import { ReactNode } from "react";

interface ITitle {
  title: string;
  icon?: string;
  children?: ReactNode;
  isSidebar?: boolean;
  isMdiReactIcon?: boolean;
}

const Title = ({
  title,
  icon,
  isSidebar,
  children,
  isMdiReactIcon
}: ITitle) => {
  const { t } = useTranslation("common");

  return (
    <h3
      className={`${
        isMdiReactIcon ? `${styles.title__mdi__icon}` : "page-title"
      } ${isSidebar && `${styles.iconTitleSidebar}`} `}
    >
      {icon && <span className={`lnr lnr-${icon}`} />}
      {children}
      {t(`${title}`)}
    </h3>
  );
};

export default Title;

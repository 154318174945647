import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

interface IProps {
  location: any;
  children?: any;
}

const ScrollToTop = ({ location, children }: IProps) => {
  useEffect(() => {
    if (location && location.pathname) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
};

export default withRouter(ScrollToTop);

import { ICreateEditUser } from "@interfaces/Users/ICreateEditUser";
import apiInstance from "@services/Shared/instanceAxios";

const updateUser = (id: string, newItem: ICreateEditUser) =>
  apiInstance({
    url: `/users/${id}`,
    method: "put",
    data: newItem,
  });

export default updateUser;

import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import { renderComponentField } from "@components/EasyDev/form/FormField";
import styles from "./DropZone.module.scss";

const DropZoneField = ({
  value,
  customHeight,
  name,
  onChange,
  accept,
  description,
  disabled,
}) => {
  const files = value;
  const onDrop = (file) => {
    onChange(
      file.map((fl) =>
        Object.assign(fl, {
          preview: URL.createObjectURL(fl),
        })
      )
    );
  };
  const removeFile = (index, e) => {
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  };

  return (
    <div
      className={`dropzone dropzone--single${
        customHeight ? " dropzone--custom-height" : ""
      } ${styles.dropzone} ${styles.fixedHeight} ${
        disabled && styles.disabled
      }`}
    >
      <Dropzone
        accept={accept}
        name={name}
        multiple={false}
        onDrop={(fileToUpload) => {
          onDrop(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps({
              onClick: (event) => disabled && event.stopPropagation(),
              onKeyDown: (event) => disabled && event.stopPropagation(),
            })}
            className={`dropzone__input ${styles.dropzoneInput}`}
          >
            {(!files || files.length === 0) && (
              <div className="dropzone__drop-here">
                <span className="lnr lnr-upload" /> {description}
              </div>
            )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {files && Array.isArray(files) && files.length > 0 && (
        <aside className={`dropzone__img ${styles.dropzoneImage}`}>
          {files[0].type === "video/mp4" ? (
            <video width="298" height="248" controls>
              <source src={files[0].preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={files[0].preview} alt="drop-img" />
          )}
          <p className="dropzone__img-name">{files[0].name}</p>
          <button
            className={`dropzone__img-delete ${styles.dropzoneImageDelete}`}
            type="button"
            onClick={(e) => removeFile(0, e)}
          >
            Remove
          </button>
        </aside>
      )}
    </div>
  );
};

DropZoneField.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  customHeight: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  ]).isRequired,
};

DropZoneField.defaultProps = {
  customHeight: false,
};

export default renderComponentField(DropZoneField);

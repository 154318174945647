import { EN } from "@constants/countryCodes";
import { IFullProject } from "@interfaces/Projects/FullProject";
import {
  KEY_CURRENT_PROJECT,
  KEY_STORE_I18N,
  KEY_STORE_USER,
} from "../constants/Env";
import { ICurrentUser } from "../interfaces/Account/currentUser";

class LocalStorageService {
  setI18nStorage = (lng: string) => {
    localStorage.setItem(KEY_STORE_I18N, lng);
  };

  getI18nStorage = () => {
    return localStorage.getItem(KEY_STORE_I18N) ?? EN;
  };

  setLocalUserStorage = (user: ICurrentUser) => {
    localStorage.setItem(KEY_STORE_USER, JSON.stringify(user));
  };

  getLocalUserStorage = (): ICurrentUser | null => {
    const localUser = localStorage.getItem(KEY_STORE_USER);
    const currentUser = localUser
      ? (JSON.parse(localUser) as ICurrentUser)
      : null;

    return currentUser;
  };

  setCurrentFullProject = (currentProject: IFullProject) => {
    localStorage.setItem(KEY_CURRENT_PROJECT, JSON.stringify(currentProject));
  };

  getCurrentFullProject = (): IFullProject | null => {
    const localFullProject = localStorage.getItem(KEY_CURRENT_PROJECT);
    const currentFullProject = localFullProject
      ? (JSON.parse(localFullProject) as IFullProject)
      : null;

    return currentFullProject;
  };

  removeLocalStorage = (key: string) => {
    localStorage.removeItem(key);
  };
}
export default new LocalStorageService();

import { ReactElement, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import useMediaUploaderMutator from "@hooks/Media/useMediaUploaderMutator";
import Error from "@components/Shared/Error/Error";
import BoxIcon from "@components/BoxIcon";
import Loader from "@components/Loader";
import Header from "../Header";
import styles from "./EditNameplate.module.scss";
import { useParams } from "react-router-dom";
import editTextValidations from "@validators/ArtUploader/EditText.validator";
import { OnBlur, OnChange } from "react-final-form-listeners";
import { FormApi } from "final-form";
import useGetNameplatePreview from "@hooks/Media/useGetNameplatePreview";
import NameplatePreview from "./components/NameplatePreview";
import BoxToolbar from "../ButtonToolbar";
import renderSelectField from "@easydev/form/Select";
import { GetFontSize, GetFontSizes } from "@helpers/fontSize";
import { GetFontFamilies, GetFontFamily } from "@helpers/fontFamily";
import textRowsValidate from "@validators/ArtUploader/textRows.validate";
import setFieldValueMutator from "@helpers/setFieldValueMutator";
import { MediaType } from "@enums/mediaType";
import SketchColorPicker from "@components/EasyDev/form/SketchColorPicker";
import { defaultFontColor, defaultBackgroundColor } from "@constants/fontColor";
import { ArtUploadStep } from "@enums/artUploadStep";

interface IEditNameplate {
  initialValues: IArtUploader;
  onFormSubmit: (values: IArtUploader) => void;
  onButtonCloseClicked: () => void;
  isLoading: boolean;
}

const EditNameplate = ({
  initialValues,
  onFormSubmit,
  onButtonCloseClicked,
  isLoading,
}: IEditNameplate): ReactElement => {
  const { mediaId } = useParams<{
    mediaId: string;
  }>();
  const { t } = useTranslation();
  const { mediaMutator, selectProceed, selectCancel, isOpen } =
    useMediaUploaderMutator();
  const {
    setNameplateText,
    setNameplateFontFamily,
    setNameplateFontSize,
    setNameplateFontColor,
    setNameplateBackgroundColor,
    nameplatePreview,
    isLoading: isLoadingPreview,
  } = useGetNameplatePreview(mediaId);

  useEffect(() => {
    if (initialValues.nameplateText && !initialValues.nameplateImageUrl) {
      setNameplateText(initialValues.nameplateText);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const GetNameplatePreview = (
    form: FormApi<IArtUploader, Partial<IArtUploader>>
  ) => {
    setNameplateText(form.getFieldState("nameplateText")?.value);
    setNameplateFontFamily(
      form.getFieldState("nameplateFontFamily")?.value?.value
    );
    setNameplateFontSize(form.getFieldState("nameplateFontSize")?.value?.value);
    setNameplateFontColor(form.getFieldState("nameplateFontColor")?.value?.hex);
    setNameplateBackgroundColor(
      form.getFieldState("nameplateBackgroundColor")?.value?.hex
    );
  };

  const fullMutators = { ...mediaMutator, ...setFieldValueMutator };

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validate={editTextValidations}
      mutators={fullMutators}
    >
      {({
        handleSubmit,
        form,
        form: {
          mutators: { setValue },
        },
      }) => {
        return (
          <Row>
            <form
              className={`form ${styles.formNameplate}`}
              onSubmit={handleSubmit}
            >
              <Header
                title={`Step 3 - ${t("artuploader:title_edit_nameplate")}`}
                form={form}
                isOpen={isOpen}
                handleConfirm={selectProceed}
                handleCancel={selectCancel}
              />
              <Col
                md={6}
                className={`${styles.boxPreview} ${styles.fieldNameplate}`}
              >
                <NameplatePreview
                  isLoading={isLoadingPreview}
                  linkImage={
                    nameplatePreview ?? initialValues.nameplateImageUrl
                  }
                />
              </Col>
              <Col md={6} className={styles.box}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("core:common:description")}
                  </span>
                  <div
                    className={`form__form-group-field ${styles.fieldNameplate}`}
                  >
                    <Field
                      maxLength={
                        initialValues.mediaType === MediaType.text ? 450 : 30
                      }
                      component="textarea"
                      rows={initialValues.mediaType === MediaType.text ? 10 : 2}
                      name="nameplateText"
                      placeholder={t("artuploader:nameplate_text")}
                    />
                    <OnChange name="nameplateText">
                      {(value, previous) =>
                        textRowsValidate(
                          value,
                          "nameplateText",
                          previous,
                          setValue
                        )
                      }
                    </OnChange>
                    <OnBlur name="nameplateText">
                      {() => {
                        GetNameplatePreview(form);
                      }}
                    </OnBlur>
                    <Error name="nameplateText" />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Font Family | Font Size
                  </span>
                  <div
                    className={`form__form-group-field ${styles.fontOptions} ${styles.fieldNameplate}`}
                  >
                    <Field
                      name="nameplateFontFamily"
                      component={renderSelectField}
                      type="text"
                      options={GetFontFamilies()}
                      defaultValue={GetFontFamily("Century")}
                    />
                    <OnChange name="nameplateFontFamily">
                      {() => {
                        GetNameplatePreview(form);
                      }}
                    </OnChange>
                    <Field
                      name="nameplateFontSize"
                      component={renderSelectField}
                      type="text"
                      options={GetFontSizes()}
                      defaultValue={GetFontSize("")}
                    />
                    <OnChange name="nameplateFontSize">
                      {() => {
                        GetNameplatePreview(form);
                      }}
                    </OnChange>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Font Color | Background Color
                  </span>
                  <div
                    className={`form__form-group-field ${styles.colorOptions} ${styles.fieldNameplate}`}
                  >
                    <Field
                      name="nameplateFontColor"
                      component={SketchColorPicker}
                      props={{ defaultColor: defaultFontColor }}
                    />
                    <OnChange name="nameplateFontColor">
                      {() => {
                        GetNameplatePreview(form);
                      }}
                    </OnChange>
                    <Field
                      name="nameplateBackgroundColor"
                      component={SketchColorPicker}
                      props={{ defaultColor: defaultBackgroundColor }}
                    />
                    <OnChange name="nameplateBackgroundColor">
                      {() => {
                        GetNameplatePreview(form);
                      }}
                    </OnChange>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("core:common:link")}
                  </span>
                  <div
                    className={`form__form-group-field ${styles.fieldNameplate}`}
                  >
                    <Field
                      component="input"
                      autoComplete="off"
                      name="nameplateLink"
                      placeholder={t("artuploader:nameplate_link")}
                    />
                    <BoxIcon
                      id="ButtonNameplateLink"
                      title={t("core:notifications.onclick_link_nameplate")}
                    />
                  </div>
                  <Error name="nameplateLink" />
                </div>
              </Col>

              <BoxToolbar
                disabled={false}
                mediaType={initialValues.mediaType}
                onClick={onButtonCloseClicked}
                artUploadStep={ArtUploadStep.EDIT_NAMEPLATE_ASSET}
              />
            </form>
          </Row>
        );
      }}
    </Form>
  );
};

export default EditNameplate;

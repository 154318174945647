import React, { useContext, useState } from "react";
import { showNotification } from "@components/EasyDev/Notification";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import Title from "@components/Title";
import updateSettingsURLs from "@services/Settings/updateSettingsURLs";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import SettingsForm from "./components/SettingsForm";
import NotAccess401 from "@pages/NotAccess401";
import { ResourcesAccess } from "@enums/ResourcesAccess";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import UserContext from "@context/UserContext/UserContext";
import localStorageServices from "@utils/localStorage.services";

interface ISettingsFormValues {
  defaultImage: string;
  defaultLink: string;
}

const Settings = () => {
  const [formValues, setFormValues] = useState<ISettingsFormValues>(
    {} as ISettingsFormValues
  );
  const { t } = useTranslation("common");
  const [modalStatus, setModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { setCurrentProject, currentProject } = useContext(UserContext);

  if (!IsEnabledResource(ResourcesAccess.OWNER_ADMIN)) {
    return <NotAccess401 />;
  }

  const handleUpdateSettings = () => {
    setIsLoading(true);
    const values = {
      defaultImage: formValues?.defaultImage ?? "",
      defaultLink: formValues?.defaultLink ?? "",
    };
    updateSettingsURLs(projectId, values)
      .then(async (result) => {
        const currentUpdatedProject = currentProject;

        if (currentUpdatedProject) {
          currentUpdatedProject.project = result.data;
          localStorageServices.setCurrentFullProject(currentUpdatedProject);
          setCurrentProject(currentUpdatedProject);
        }

        showNotification(
          `${t("core:common.settings")}`,
          t("core:notifications.update_success_message"),
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          `${t("core:common.user")}`,
          `${t("core:notifications.err_message")} ${formValues?.defaultImage}`,
          "danger"
        );
      })
      .finally(() => {
        setIsLoading(false);
        handleModalStatus();
        history.go(0);
      });
  };

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <Container>
      <ModalConfirmation
        icon="flag"
        modalType="warning"
        title={`${t("core:common.settings")} | ${t("core:common.confirm")}`}
        body={`${t("core:notifications.modify_confirm")}?`}
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirm={handleUpdateSettings}
      />
      <Row>
        <Col md={12}>
          <Title title={t("core:common.settings")} icon="cog" />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="subhead">
                  {`${t("core:common.settings_message1")}`}
                </h5>
                <h5 className="subhead">
                  {`${t("core:common.settings_message2")}`}
                </h5>
              </div>
              <SettingsForm
                isLoading={isLoading}
                projectId={projectId}
                setFormValues={setFormValues}
                handleModalStatus={handleModalStatus}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;

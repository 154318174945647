import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FormImage.module.scss";

interface IFormImage {
  linkImage: string | undefined;
}

const FormImage = ({ linkImage }: IFormImage): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.filePreview}>
        <img src={linkImage} alt="Asset preview" />
        <p>{t("artuploader:image_preview")}</p>
      </div>
    </>
  );
};

export default FormImage;

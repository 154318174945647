import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { IMediaUpdateDto } from "@interfaces/Media/MediaUpdateDto";

const updateMediaMapper = (values: IArtUploader): IMediaUpdateDto => ({
  onClickLink: values.onclick ?? null,
  nameplateText: values.nameplateText ?? null,
  nameplateLink: values.nameplateLink,
  nameplateFontFamily: values.nameplateFontFamily?.value,
  nameplateFontSize: values.nameplateFontSize?.value
    ? values.nameplateFontSize.value
    : undefined,
  enabled: values.showFrame,
  type: values.mediaType,
});

export default updateMediaMapper;

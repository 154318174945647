import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import InformationOutlineIcon from "mdi-react/InformationOutlineIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import CommentAlertOutlineIcon from "mdi-react/CommentAlertOutlineIcon";
import CloseCircleOutlineIcon from "mdi-react/CloseCircleOutlineIcon";

const AlertComponent = ({ color, className, icon, children }) => {
  let Icon;

  switch (color) {
    case "info":
      Icon = <InformationOutlineIcon />;
      break;
    case "success":
      Icon = <ThumbUpOutlineIcon />;
      break;
    case "warning":
      Icon = <CommentAlertOutlineIcon />;
      break;
    case "danger":
      Icon = <CloseCircleOutlineIcon />;
      break;
    default:
      console.log("Wrong color!");
      break;
  }

  return (
    <Alert color={color} className={className} isOpen={true}>
      {icon && <div className="alert__icon">{Icon}</div>}
      <div className="alert__content">{children}</div>
    </Alert>
  );
};

export default AlertComponent;

AlertComponent.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AlertComponent.defaultProps = {
  color: "",
  icon: false,
  className: "",
};

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { Collapse } from "reactstrap";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

interface ISidebarCategory {
  title: string;
  icon?: string;
  isNew?: boolean;
  children?: ReactElement;
  sidebarCollapse?: boolean;
  isProjectsPath?: boolean;
  setIsProjectsPath?: Dispatch<SetStateAction<boolean>>;
  isSubmenuActive?: boolean;
  isBuildingLayoutPath?: boolean;
}

const SidebarCategory = ({
  title,
  icon,
  isNew,
  children,
  isSubmenuActive,
  isBuildingLayoutPath,
}: ISidebarCategory): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const categoryClass = classNames({
    "sidebar__category-wrap": true,
    "sidebar__category-wrap--open": isOpen,
  });
  const location = useLocation();

  useEffect(() => {
    if (isBuildingLayoutPath || isSubmenuActive) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className={categoryClass}>
      <button
        className="sidebar__link sidebar__category"
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {icon && <span className={`sidebar__link-icon lnr lnr-${icon}`} />}
        <p className="sidebar__link-title">
          {title}
          {isNew && <span className="sidebar__category-new" />}
        </p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse isOpen={isOpen} className="sidebar__submenu-wrap">
        <ul className="sidebar__submenu">
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
};

SidebarCategory.defaultProps = {
  icon: "",
  isNew: false,
};

export default SidebarCategory;

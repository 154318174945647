import apiInstance from "@services/Shared/instanceAxios";
import { IExtendedFile } from "@interfaces/Shared/Files/Files";

const createMediaImage = (mediaId: string, file: IExtendedFile) => {
  const data = new FormData();
  data.append("image", file);

  return apiInstance({
    url: `/media/${mediaId}/image`,
    method: "post",
    headers: {
      "Content-type": "multipart/form-data",
    },
    data,
  });
};

export default createMediaImage;

import { useState, useEffect } from "react";
import { IGatingSystem } from "@interfaces/GatingSystem/GatingSystem";
import useGetGatingSystem from "./useGetGatingSystem";

interface IUseGatingSystem {
  gatingSystem: IGatingSystem | undefined;
  updateGatingSystem: (gatingSystem: IGatingSystem) => void;
  loading: boolean;
}

const useGatingSystem = (projectId: string): IUseGatingSystem => {
  const { data, loading } = useGetGatingSystem(projectId);
  const [gatingSystem, setGatingSystem] = useState<IGatingSystem | undefined>(
    undefined
  );

  useEffect(() => {
    if (data) {
      updateGatingSystem(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const updateGatingSystem = (data: IGatingSystem) => {
    setGatingSystem(data);
  };

  return {
    gatingSystem,
    updateGatingSystem,
    loading,
  };
};

export default useGatingSystem;

import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "@scss/app.scss";
import Router from "./Router/Router";
import ScrollToTop from "./ScrollToTop";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import { config as i18nextConfig } from "../../translations";
import storageService from "@utils/localStorage.services";

i18n.init(i18nextConfig);
i18n.changeLanguage(storageService.getI18nStorage());

const App = () => {
  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ScrollToTop>
          <Router />
        </ScrollToTop>
      </I18nextProvider>
    </BrowserRouter>
  );
};

export default App;

import Projects from "@pages/Projects/Projects";
import ProjectDetail from "@pages/ProjectDetail";
import styles from "@pages/Layout/Layout.module.scss";
import Users from "@pages/Users";
import GatingSystem from "@pages/GatingSystem";
import CreateUsers from "@pages/Users/CreateUsers";
import ModifyUser from "@pages/Users/ModifyUser/ModifyUser";
import ArtUploader from "@pages/ArtUploader";
import Place from "@pages/Place";
import { Route, Switch } from "react-router-dom";
import Layout from "@pages/Layout";
import {
  PROJECTS,
  PROJECTS_DETAIL,
  USERS,
  GATINGSYSTEM,
  USERS_CREATE,
  USERS_MODIFY,
  MEDIA_UPLOADER,
  PLACE_DETAIL,
  SETTINGS,
} from "@constants/Routes";
import Settings from "@pages/Settings";

const WrappedRoutes = () => {
  return (
    <div>
      <Layout />
      <div className={`container__wrap ${styles.container__wrap}`}>
        <Switch>
          <Route exact path={PROJECTS_DETAIL} component={ProjectDetail} />
          <Route exact path={PLACE_DETAIL} component={Place} />
          <Route exact path={PROJECTS} component={Projects} />
          <Route exact path={GATINGSYSTEM} component={GatingSystem} />
          <Route exact path={MEDIA_UPLOADER} component={ArtUploader} />
          <Route exact path={USERS} component={Users} />
          <Route exact path={USERS_CREATE} component={CreateUsers} />
          <Route exact path={USERS_MODIFY} component={ModifyUser} />
          <Route exact path={SETTINGS} component={Settings} />
        </Switch>
      </div>
    </div>
  );
};

export default WrappedRoutes;

import { MediaType } from "@enums/mediaType";
import { createFile } from "@helpers/createFileFromUri";
import { stepFromMediaType } from "@helpers/stepFromMediaType";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { IMedia } from "@interfaces/Media/Media";
import { defaultArtUploader } from "./ArtUploader.default";

const artUploaderMapper = async (artFromApi: IMedia): Promise<IArtUploader> => {
  let artMapped: IArtUploader = defaultArtUploader;

  let imageFile = await createFile(artFromApi.imageUrl);
  artMapped.nameplateText = artFromApi.nameplate?.text;
  artMapped.nameplateLink = artFromApi.nameplate?.link;
  artMapped.nameplateImageUrl = artFromApi.nameplate?.imageUrl;
  artMapped.onclick = artFromApi.onClickLink;
  artMapped.showFrame = artFromApi.enabled;
  artMapped.mediaType = artFromApi.type;
  artMapped.step = stepFromMediaType(artFromApi.type);

  if (artFromApi.type === MediaType.video) {
    let videoFile = await createFile(artFromApi.videoUrl);
    artMapped.dropzone = videoFile ? [videoFile] : [];
    artMapped.thumbnail = imageFile ? [imageFile] : [];
  } else if (artFromApi.type === MediaType.image) {
    artMapped.dropzone = imageFile ? [imageFile] : [];
  }

  return artMapped;
};

export default artUploaderMapper;

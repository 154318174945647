import { generatePath, Link, useParams } from "react-router-dom";
import { Container, Col } from "reactstrap";
import { PROJECTS_DETAIL } from "@constants/Routes";

const NotAccess401 = () => {
  const { projectId } = useParams<{
    projectId: string;
  }>();

  return (
    <Container>
      <Col>
        <div className="not-found__content">
          <p className="code">401</p>
          <h3 className="not-found__info">
            Ooops! The page you are looking for could not be access :(
          </h3>
          <Link
            className="btn btn-primary"
            to={generatePath(PROJECTS_DETAIL, {
              projectId,
            })}
          >
            <p className="topbar__link-title">Back to project</p>
          </Link>
        </div>
      </Col>
    </Container>
  );
};

export default NotAccess401;

export const formatSizeUnits = (bytes: number) => {
  let totalSize = "0 bytes";
  if (bytes >= 1073741824) {
    totalSize = (bytes / 1073741824).toFixed(2) + " gb";
  } else if (bytes >= 1048576) {
    totalSize = (bytes / 1048576).toFixed(2) + " mb";
  } else if (bytes >= 1024) {
    totalSize = (bytes / 1024).toFixed(2) + " kb";
  } else if (bytes > 1) {
    totalSize = bytes + " bytes";
  } else if (bytes === 1) {
    totalSize = bytes + " byte";
  }
  return totalSize;
};

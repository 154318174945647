import { ReactElement } from "react";
import ReactPlayer from "react-player/lazy";
import styles from "./Player.module.scss";
import { IMedia } from "@interfaces/Media/Media";

interface IPlayer {
  placeMedia: IMedia;
}

const Player = (placeMedia: IPlayer): ReactElement => {
  const {
    placeMedia: { nameplate: title, videoUrl },
  } = placeMedia;

  return videoUrl?.indexOf(".mp4") !== -1 ||
    videoUrl?.indexOf(".m3u8") !== -1 ? (
    <div className={styles.reactPlayer__wrapper}>
      <ReactPlayer
        controls={true}
        volume={0.5}
        muted={true}
        loop={true}
        playing={false}
        url={videoUrl}
        className={styles.reactPlayer__video}
        width="100%"
      />
    </div>
  ) : (
    <img src={videoUrl} alt={title?.text} />
  );
};

export default Player;

import { IMedia } from "@interfaces/Media/Media";
import styles from "./MediaItem.module.scss";
import { useTranslation } from "react-i18next";
import Player from "@components/Player";
import MediaDefault from "./components/MediaDefault";
import MediaImage from "./components/MediaImage";
import { VideoStatus } from "@enums/videoStatus";

interface IMediaItem {
  placeMedia: IMedia;
}

const MediaItem = ({ placeMedia }: IMediaItem) => {
  const { t } = useTranslation("common");
  const stylesMedia = !placeMedia.enabled
    ? [styles.mediaItem__frame, styles.mediaItem__blur]
    : [styles.mediaItem__frame];

  const isNotEmptyMedia =
    placeMedia.imageUrl || placeMedia.videoUrl ? true : false;

  const isVideoPadStatus =
    placeMedia.videoPad?.status !== VideoStatus.done &&
    placeMedia.videoUrl &&
    placeMedia.videoPad
      ? true
      : false;

  return (
    <>
      <div className={styles.mediaItem__container}>
        <div className={stylesMedia.join(" ")}>
          {!isNotEmptyMedia || isVideoPadStatus ? (
            <MediaDefault />
          ) : (
            <>
              {placeMedia.videoUrl ? (
                <Player placeMedia={placeMedia} />
              ) : (
                placeMedia.imageUrl && (
                  <MediaImage
                    imageUrl={placeMedia.imageUrl}
                    link={placeMedia.onClickLink}
                  />
                )
              )}
            </>
          )}
        </div>
        {!placeMedia.enabled && <span>[{t("core:common.hidden_frame")}]</span>}
      </div>
    </>
  );
};

export default MediaItem;

import { Link, generatePath } from "react-router-dom";
import { Button } from "reactstrap";
import { PROJECTS_DETAIL } from "@constants/Routes";
import { IProject } from "@interfaces/Projects/Project";
import { t } from "i18next";

const listProjectsMapper = (projectsFromApi: IProject[]) => {
  const listProject = projectsFromApi.map(
    ({ id, link, metaverse, name }, index) => ({
      order: index + 1,
      id,
      metaverse,
      name,
      actions: (
        <>
          {link ? (
            <a
              href={link}
              target="_blank"
              title={`${t("core:common.go_to")} ${t("core:common.metaverse")}`}
              rel="noopener noreferrer"
            >
              <Button className="btn-sm btn-primary">
                <span className="lnr lnr-earth" />
              </Button>
            </a>
          ) : (
            <Button
              style={{ marginRight: 0 }}
              title={`${t("core:common.metaverse")} ${t(
                "core:common.not_available"
              )}`}
              className="btn-sm"
            >
              <span className="lnr lnr-earth" />
            </Button>
          )}

          <Link
            title={`${t("core:common.go_to")} ${t("core:common.edit")} ${t(
              "core:common.project"
            )}`}
            to={generatePath(PROJECTS_DETAIL, {
              projectId: id
            })}
          >
            <Button className="btn-sm btn-warning">
              <span className="lnr lnr-pencil" />
            </Button>
          </Link>
        </>
      )
    })
  );

  return listProject;
};

export default listProjectsMapper;

import loading from "@images/shared/loading.gif";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NameplatePreview.module.scss";

interface INameplatePreview {
  linkImage: string | undefined | null;
  isLoading: boolean;
}

const NameplatePreview = ({
  linkImage,
  isLoading,
}: INameplatePreview): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.boxPreview}>
        {isLoading ? (
          <>
            <img src={loading} alt="loading preview" />
            <p>creating nameplate...</p>
          </>
        ) : linkImage ? (
          <>
            <img
              src={linkImage}
              className={styles.imagePreview}
              alt="Nameplate preview"
            />
            <p>{t("artuploader:image_preview")}</p>
          </>
        ) : (
          <p>
            if you want a nameplate, you need to write a text.
            <br /> On tab event, you can see a preview image.
          </p>
        )}
      </div>
    </>
  );
};

export default NameplatePreview;

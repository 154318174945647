import { CardBody } from "reactstrap";
import styles from "./PlaceCard.module.scss";
import { generatePath, Link, useParams } from "react-router-dom";
import { IPlace } from "@interfaces/Spaces/Place";
import { PLACE_DETAIL } from "@constants/Routes";
import { ISpace } from "@interfaces/Spaces/Space";

interface IPlaceCard {
  space: ISpace;
  place: IPlace;
}

const PlaceCard = ({ space, place }: IPlaceCard) => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <CardBody className={styles.place__card}>
      <Link
        to={generatePath(PLACE_DETAIL, {
          projectId,
          placeId: place.name,
          spaceId: space.name,
        })}
        className={styles.anchor__overlay}
      >
        <span>{place.name}</span>
      </Link>
    </CardBody>
  );
};

export default PlaceCard;

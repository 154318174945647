import { IPasswordDto } from "@interfaces/GatingSystem/PasswordDto";

const updatePasswordMapper = (formValues: any): IPasswordDto => {
  return {
    message: formValues.message,
    password: formValues.password,
    enabled: formValues.state,
  };
};

export default updatePasswordMapper;

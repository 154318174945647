import { Field } from "react-final-form";
import { ISwitch } from "@interfaces/Shared/Switch/Switch";
import styles from "./Switch.module.scss";

const Switch = ({
  name,
  disabled,
  checked,
  className,
  leftText,
  rightText,
  form,
}: ISwitch) => {
  return (
    <label
      htmlFor={name}
      className={`${styles.switch} ${className} ${disabled && styles.disabled}`}
    >
      {checked !== undefined ? (
        <Field
          className={styles.innerInput}
          type="checkbox"
          component="input"
          name={name}
          initialValue={checked}
          disabled={disabled}
        />
      ) : (
        <Field
          name={name}
          type="checkbox"
          disabled={disabled}
          render={({ input }) => (
            <input
              {...input}
              className={styles.innerInput}
              onChange={(event) => form?.mutators.confirmSwitch(event)}
            />
          )}
        />
      )}
      <span className={styles.left}>{leftText}</span>
      <span className={styles.right}>{rightText}</span>
    </label>
  );
};

export default Switch;

import { METADATA_SERVICES_URL } from "@constants/Env";
import { INFTMetadata } from "@interfaces/Metadata/NFTMetadata";
import axios from "axios";

const getMetadataService = (contractAddress: string, tokenId: string) =>
  axios.request<INFTMetadata>({
    baseURL: METADATA_SERVICES_URL,
    url: `/nft/contract/${contractAddress}/token-id/${tokenId}`,
  });

export default getMetadataService;

import { ReactElement, useContext, useState } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import { shortenWalletAddress } from "@helpers/shortenWallet";
import TopbarMenuLink from "../TopbarMenuLink";
import UserContext from "@context/UserContext/UserContext";
import WalletIcon from "mdi-react/WalletIcon";
import { useTranslation } from "react-i18next";
import { HOME } from "@constants/Routes";

const TopbarProfile = (): ReactElement => {
  const { t } = useTranslation(["core"]);
  const { currentUser, handleModalStatus } = useContext(UserContext);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="topbar__profile">
      <button
        type="button"
        className="topbar__avatar"
        onClick={handleToggleCollapse}
      >
        <WalletIcon className="topbar__icon" />
        <p className="topbar__avatar-name">
          {currentUser?.walletAddress &&
            shortenWalletAddress(currentUser.walletAddress)}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title={t("core:navigation.log_out")}
            icon="exit"
            path={HOME}
            onclick={handleModalStatus}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default TopbarProfile;

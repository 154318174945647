import axios from "axios";
import { fromBuffer } from "file-type/browser";
import { IExtendedFile } from "@interfaces/Shared/Files/Files";

export const createFile = async (
  imageUrl: string
): Promise<IExtendedFile | undefined> => {
  if (!imageUrl) return;
  const url = imageUrl;
  try {
    const { data } = await axios.get(url, { responseType: "arraybuffer" });
    const filetype = await fromBuffer(data);
    const filename = url.split("/").pop();
    const buffer = Buffer.from(data, "binary");

    let file: IExtendedFile = new File([buffer], filename ?? "unkwnown.jpg", {
      type: filetype?.mime ?? "image/jpg",
    });

    file.preview = imageUrl;

    return file;
  } catch (error) {
    console.error(error);
  }
};

import CMSHome from "@pages/LogIn/components/CMSHome";
import { Button } from "reactstrap";
import { useContext } from "react";
import UserContext from "@context/UserContext/UserContext";

const LogIn = () => {
  const { handleConnect } = useContext(UserContext);

  return (
    <div className="account home">
      <div className="account__wrapper">
        <CMSHome />
        <h4 className="slogan">
          Connect your wallet to start managing your lands
        </h4>
        <div className="account__or">
          <p className="cms">CMS</p>
        </div>
        <div className="account__social">
          <Button
            className="account__social-btn account__social-btn--ethereum"
            onClick={handleConnect}
          >
            Connect Wallet
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LogIn;

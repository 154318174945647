import { Button, Col, Container, Row } from "reactstrap";
import Table from "@easydev/Tables/Table";
import { generatePath, Link, useParams } from "react-router-dom";
import { USERS_CREATE } from "@constants/Routes";
import { useTranslation } from "react-i18next";
import Title from "@components/Title";
import Loader from "@components/Loader";
import useUsers from "@hooks/Users/useUsers";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import NotAccess401 from "@pages/NotAccess401";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import { ResourcesAccess } from "@enums/ResourcesAccess";

const Users = () => {
  const { t } = useTranslation("common");
  const { projectId } = useParams<{
    projectId: string;
  }>();

  const {
    columns,
    users,
    isLoading,
    userData,
    modalStatus,
    handleModalStatus,
    handleConfirmDelete,
  } = useUsers(projectId);

  if (!IsEnabledResource(ResourcesAccess.OWNER_ADMIN)) {
    return <NotAccess401 />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <ModalConfirmation
        icon="trash"
        modalType="danger"
        title={`${t("core:common.users")} | ${t("core:common.delete_confirm")}`}
        body={`${t("core:notifications.delete_message")} "${userData?.name}"?`}
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirm={handleConfirmDelete}
      />

      <Row>
        <Col md={12}>
          <Title title={t("core:common.users")} icon="users" />
        </Col>
      </Row>

      <div className="btn-container">
        <div className="buttons">
          <Link to={generatePath(USERS_CREATE, { projectId })}>
            <Button color="success">
              {t("core:common.create")} {t("core:common.user")}
            </Button>
          </Link>
        </div>
      </div>

      <Table columns={columns} items={users} />
    </Container>
  );
};

export default Users;

import { showNotification } from "@components/EasyDev/Notification";
import { useTranslation } from "react-i18next";

const useMediaMessage = () => {
  const { t } = useTranslation();

  const SuccessMediaUpload = () => {
    showNotification(
      `${t("core:common.media")}`,
      t("core:notifications.create_success_message"),
      "success"
    );
  };

  const ErrorMediaUpload = (message: string | undefined = undefined) => {
    showNotification(
      `${t("core:common.media")}`,
      message ?? `${t("core:notifications.err_message")}`,
      "danger"
    );
  };

  return {
    SuccessMediaUpload,
    ErrorMediaUpload,
  };
};

export default useMediaMessage;

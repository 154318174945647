import { MutableState } from "final-form";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import useConfirm from "@hooks/Shared/useConfirm";

const useMediaUploaderMutator = () => {
  const { isConfirmed, proceed, cancel, isOpen } = useConfirm();

  const mediaMutator = {
    confirmSwitch: (args: any[], state: MutableState<IArtUploader>) => {
      if (!args[0].target.checked) {
        isConfirmed().then((confirm) => {
          if (!confirm) {
            return;
          }
          state.fields["showFrame"].change(false);
        });
      }
      state.fields["showFrame"].change(true);
    },
  };

  const selectProceed = () => {
    proceed && proceed();
  };

  const selectCancel = () => {
    cancel && cancel();
  };

  return {
    mediaMutator,
    selectProceed,
    selectCancel,
    isOpen,
  };
};

export default useMediaUploaderMutator;

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Title from "@components/Title";
import TabContainer from "@components/TabContainer/TabContainer";
import { useTranslation } from "react-i18next";
import { ITabItem } from "@interfaces/Shared/TabItem";
import TabPassword from "./components/TabPassword";
import TabWhitelist from "./components/TabWhitelist";
import TabNFTCollectors from "./components/TabNFTCollectors";
import NotAccess401 from "@pages/NotAccess401";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import { ResourcesAccess } from "@enums/ResourcesAccess";

const GatingSystem = () => {
  const { t } = useTranslation("common");

  const tabs: ITabItem[] = [
    {
      id: "1",
      name: t("core:common.password"),
      tab: <TabPassword />,
    },
    {
      id: "2",
      name: t("core:common.whitelist"),
      tab: <TabWhitelist />,
    },
    {
      id: "3",
      name: t("core:common.nft_collectors"),
      tab: <TabNFTCollectors />,
    },
  ];

  if (!IsEnabledResource(ResourcesAccess.GATINGSYSTEM)) {
    return <NotAccess401 />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title title={t("core:common.gating_system")} icon="lock" />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="subhead">
                  {t("core:common.gating_system_message")}
                </h5>
              </div>
              <div className="tabs tabs--justify tabs--bordered-bottom">
                <TabContainer tabItems={tabs} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GatingSystem;

import { API_URL } from "@constants/Env";
import localStorageServices from "@utils/localStorage.services";
import { useAxios } from "use-axios-client";
import { Config, Props } from "use-axios-client/bin/useBaseAxios";

const useApiCall = <T>(config: Config): Props<T> => {
  const currentUser = localStorageServices.getLocalUserStorage();

  return useAxios<T>({
    baseURL: API_URL,
    headers: {
      "auth-public-address": currentUser?.walletAddress ?? "",
      "auth-signature": currentUser?.signature ?? "",
    },
    ...config,
  });
};

export default useApiCall;

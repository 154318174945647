import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

const walletAddressValidate = (walletAddress: string) => {
  let errMsg = undefined;

  errMsg = emptyFieldValidate(walletAddress, t("core:common.wallet"));

  if (walletAddress) {
    if (walletAddress.length !== 42) {
      errMsg = t("core:validators.walletLength");
    }
  }

  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(walletAddress)) {
    errMsg = t("core:validators.symbols-not-allowed");
  }

  return errMsg;
};

export default walletAddressValidate;

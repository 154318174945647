import { useState, useEffect, useContext } from "react";
import { t } from "i18next";
import IBaseDeleteItem from "@interfaces/Shared/baseDeleteItem";
import { IBaseUse } from "@interfaces/Shared/baseUse";
import useGetUsers from "./useGetUsers";
import { IUser } from "@interfaces/Users/Users";
import listUsersMapper from "@mappers/Users/Users.mapper";
import { showNotification } from "@components/EasyDev/Notification";
import ListUsersColumns from "@pages/Users/ListUsers/ListUsers.columns";
import deleteUser from "@services/Users/deleteUsers";
import { useHistory } from "react-router-dom";
import UserContext from "@context/UserContext/UserContext";
import { TUserContext } from "@userContext";
import { ResourcesAccess } from "@enums/ResourcesAccess";
import { IsEnabledResource } from "@utils/security/resourcesAccess";

interface IUseClients extends IBaseUse {
  userData: IBaseDeleteItem | undefined;
  users: IUser[] | undefined;
}

const useUsers = (projectId: string): IUseClients => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<IUser[] | undefined>([]);
  const [userData, setUserData] = useState<IBaseDeleteItem | undefined>(
    undefined
  );
  const [modalStatus, setModalStatus] = useState(false);
  const { loading, data: response } = useGetUsers(projectId);
  const { currentUser } = useContext<TUserContext>(UserContext);

  const isUserAdmin = IsEnabledResource(ResourcesAccess.ADMIN);
  const isUserOwner = IsEnabledResource(ResourcesAccess.OWNER);

  const usersMapped =
    response &&
    listUsersMapper(
      response,
      projectId,
      currentUser,
      handleDelete,
      isUserAdmin,
      isUserOwner
    );
  const columns = ListUsersColumns();

  useEffect(() => {
    setUsers(usersMapped);
    setIsLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, loading]);

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  const handleConfirmDelete = () => {
    userData &&
      deleteUser(userData?.id)
        .then(async () => {
          const itemsFiltered = users?.filter(({ id }) => id !== userData?.id);
          setUsers(itemsFiltered);
        })
        .catch((error: { response: { data: { error: string } } }) => {
          showNotification(
            `${t("core:common.users")} ${t("core:common.delete")}`,
            `${t("core:notifications.err_message")} ${
              error?.response?.data?.error
            }`,
            t("core:common.danger")
          );
        })
        .finally(() => {
          setIsLoading(false);
          handleModalStatus();
          history.go(0);
        });
  };

  function handleDelete(id: string, _address: string, name: string) {
    setUserData({ id, name });
    handleModalStatus();
  }

  return {
    modalStatus,
    isLoading,
    users,
    userData,
    columns,
    handleConfirmDelete,
    handleModalStatus,
    isUserOwner,
    isUserAdmin
  };
};

export default useUsers;

import styles from "./MediaBadge.module.scss";
import { Badge } from "reactstrap";
import { ElementType } from "@enums/elementType";

interface IMediaBadge {
  index?: number;
  elementType: ElementType | null;
}

const MediaBadge = ({ index, elementType }: IMediaBadge) => (
  <div className={styles.media__badge__container}>
    {index && (
      <Badge color="dark" className={styles.media__badge__item}>
        <span>#{index}</span>
      </Badge>
    )}

    {elementType && (
      <Badge color="dark" className={styles.media__badge__item}>
        <span>{elementType}</span>
      </Badge>
    )}
  </div>
);

export default MediaBadge;

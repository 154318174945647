import { IPasswordDto } from "@interfaces/GatingSystem/PasswordDto";
import apiInstance from "@services/Shared/instanceAxios";

const updatePassword = (projectId: string, editDto: IPasswordDto) =>
  apiInstance({
    url: `/projects/${projectId}/gating-system/password`,
    method: "put",
    data: editDto,
  });

export default updatePassword;

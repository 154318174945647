import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import useGetUsers from "@hooks/Users/useGetUsers";
import createUserMapper from "@mappers/Users/CreateUser.mapper";
import updateUser from "@services/Users/updateUser";
import { showNotification } from "@components/EasyDev/Notification";
import Loader from "@components/Loader";
import ModifyUserForm from "./ModifyUserForm";
import Title from "@components/Title";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import NotAccess401 from "@pages/NotAccess401";
import useUsers from "@hooks/Users/useUsers";
import { UserRole } from "@enums/UserRole";
import { ResourcesAccess } from "@enums/ResourcesAccess";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import UserContext from "@context/UserContext/UserContext";

const ModifyUser = () => {
  const { t } = useTranslation(["core"]);
  const [formValues, setFormValues] = useState(undefined);
  const [modalStatus, setModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { projectId, userId } = useParams<{
    projectId: string;
    userId: string;
  }>();
  const { data: users, loading } = useGetUsers(projectId);
  const filteredUser = users?.find((user) => user.id === userId);
  const { isUserAdmin } = useUsers(projectId);
  const { currentUser } = useContext(UserContext);

  if (
    !IsEnabledResource(ResourcesAccess.OWNER_ADMIN) ||
    (!currentUser?.isSuperAdmin &&
      isUserAdmin &&
      filteredUser?.role === UserRole.Owner)
  ) {
    return <NotAccess401 />;
  }

  const handleConfirmModify = () => {
    setIsLoading(true);
    const userFormatted = createUserMapper(formValues);
    updateUser(userId, userFormatted)
      .then(async () => {
        showNotification(
          `${t("core:common:users")} | ${t("core:common.modify")}`,
          t("core:notifications.modify_success_message"),
          "success"
        );
      })
      .catch((error) => {
        showNotification(
          `${t("core:common.users")} | ${t("core:common.modify")}`,
          `${t("core:notifications.err_message")} ${
            error?.response?.data?.error
          }`,
          t("core:common.danger")
        );
      })
      .finally(() => {
        setIsLoading(false);
        handleModalStatus();
        history.go(-1);
      });
  };

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <Container>
      <ModalConfirmation
        modalType="warning"
        title={`${t("core:common.users")} | ${t("core:common.modify_confirm")}`}
        body={`${t("core:notifications.modify_confirm")} ${
          filteredUser?.name
        }?`}
        icon="pencil"
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirm={handleConfirmModify}
      />
      <Row>
        <Col md={12}>
          <Title
            title={`${t("core:common.users")} | ${t("core:common.modify")}`}
            icon="users"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={8}>
          {loading ? (
            <Loader isInsideThing />
          ) : (
            <Card>
              <CardBody>
                <ModifyUserForm
                  handleModalStatus={handleModalStatus}
                  isLoading={isLoading}
                  setFormValues={setFormValues}
                  projectId={projectId}
                  userToModify={filteredUser}
                />
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ModifyUser;

import { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Topbar from "./components/Topbar/Topbar";
import Sidebar from "./components/Sidebar/Sidebar";
import Footer from "./components/Footer";
import { t } from "i18next";
import UserContext from "@context/UserContext/UserContext";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";

const Layout = () => {
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const layoutClass = classNames({
    layout: true,
    "layout--collapse": isSidebarCollapsed
  });

  const changeSidebarVisibility = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const changeMobileSidebarVisibility = () => {
    setIsSidebarShown(!isSidebarShown);
  };
  const { modalStatus, handleModalStatus, handleLogout } =
    useContext(UserContext);

  return (
    <div className={layoutClass}>
      <ModalConfirmation
        modalType="warning"
        title={`${t("core:common.voxel")} | ${t("core:navigation.log_out")}`}
        body={t("core:notifications.logout_confirm")}
        icon="exit"
        modalStatus={modalStatus}
        handleModalStatus={handleModalStatus}
        handleConfirm={handleLogout}
      />
      <Topbar
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
        changeSidebarVisibility={changeSidebarVisibility}
      />
      <Sidebar
        sidebarShow={isSidebarShown}
        sidebarCollapse={isSidebarCollapsed}
        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
      />
      <Footer />
    </div>
  );
};

export default withRouter(Layout);

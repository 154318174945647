import InformationCircleIcon from "mdi-react/InformationCircleIcon";
import { ReactElement } from "react";
import { UncontrolledTooltip } from "reactstrap";

export interface IBoxIcon {
  id: string;
  title: string;
}

const BoxIcon = ({ id, title }: IBoxIcon): ReactElement => {
  return (
    <>
      <div className="form__form-group-icon">
        <InformationCircleIcon
          id={id}
          style={{ cursor: "hand", display: "flex" }}
        />
        <UncontrolledTooltip placement="top" target={id}>
          {title}
        </UncontrolledTooltip>
      </div>
    </>
  );
};

export default BoxIcon;

import styles from "./MediaImage.module.scss";

interface IMediaImage {
  imageUrl: string;
  link?: string;
}

const MediaImage = ({ imageUrl, link }: IMediaImage) => {
  return (
    <a rel="noopener noreferrer" target="_blank" href={link ?? "#"}>
      <div
        className={styles.mediaItem__image}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
    </a>
  );
};

export default MediaImage;

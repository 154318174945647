import { ReactElement, useContext } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { PROJECTS, PROJECTS_DETAIL } from "@constants/Routes";
import SidebarLogOut from "./components/SidebarLogout";
import Title from "@components/Title";
import UserContext from "@context/UserContext/UserContext";
import { ISidebarContent } from "@interfaces/Shared/Sidebar/Sidebar";
import SidebarLink from "../SidebarLink";
import SidebarProject from "./components/SidebarProject";
import { useTranslation } from "react-i18next";

const SidebarContent = ({
  onClick,
  sidebarCollapse
}: ISidebarContent): ReactElement => {
  const { t } = useTranslation(["core"]);
  const location = useLocation();
  const { currentProject } = useContext(UserContext);
  const isProjectRoot = !!matchPath(location.pathname, {
    path: PROJECTS_DETAIL,
    strict: true
  });

  return (
    <div className="sidebar__content">
      {isProjectRoot ? (
        <>
          {currentProject && (
            <ul className="sidebar__block">
              {!sidebarCollapse && (
                <>
                  <Title
                    title={currentProject.project.name}
                    icon=""
                    isSidebar
                  />
                  <hr />
                </>
              )}
              <SidebarProject
                onClick={onClick}
                sidebarCollapse={sidebarCollapse}
              />
            </ul>
          )}
          <ul className="sidebar__block">
            <SidebarLink
              title={`${t("core:common.back-to")} ${t("core:common.projects")}`}
              icon="arrow-left-circle"
              route={PROJECTS}
              onClick={onClick}
            />
            <SidebarLogOut />
          </ul>
        </>
      ) : (
        <ul className="sidebar__block">
          <SidebarLink
            title={t("core:common.projects")}
            icon="briefcase"
            route={PROJECTS}
            onClick={onClick}
          />
          <SidebarLogOut />
        </ul>
      )}
    </div>
  );
};

SidebarContent.defaultProps = {
  sidebarCollapse: false
};

export default SidebarContent;

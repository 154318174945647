import { MediaType } from "@enums/mediaType";
import { Nameplate } from "@interfaces/Media/Nameplate";
import ImageOffOutlineIcon from "mdi-react/ImageOffOutlineIcon";
import styles from "./MediaPlaque.module.scss";
import { UncontrolledTooltip } from "reactstrap";
import Loader from "@components/Loader/Loader";
import { VideoStatus } from "@enums/videoStatus";

interface IMediaPlaque {
  nameplate?: Nameplate | null;
  enabled: boolean;
  mediaType: MediaType | null;
  videoPad?: VideoStatus | null;
}

const MediaPlaque = ({
  nameplate,
  enabled,
  mediaType,
  videoPad,
}: IMediaPlaque) => {
  let stylesMedia = !enabled
    ? [styles.media__plaque__container, styles.media__plaque__blur]
    : [styles.media__plaque__container];

  mediaType === MediaType.text &&
    stylesMedia.push(styles.media__plaque__podium);

  return nameplate?.text || nameplate?.imageUrl ? (
    <div className={stylesMedia.join(" ")}>
      {videoPad &&
      videoPad !== VideoStatus.done &&
      mediaType === MediaType.video ? (
        <div className={styles.media__plaque__videoStatus} id="videoStatus">
          <UncontrolledTooltip
            trigger="hover"
            placement="bottom"
            target={"videoStatus"}
          >
            Your video is being processed.
          </UncontrolledTooltip>
          <Loader isInsideThing text={`${videoPad}...`} />
        </div>
      ) : nameplate?.imageUrl ? (
        <img src={nameplate.imageUrl} alt="plaque media" />
      ) : (
        <span>{nameplate?.text}</span>
      )}
    </div>
  ) : mediaType === MediaType.text ? (
    <div className={styles.media__plaque__default}>
      <ImageOffOutlineIcon />
    </div>
  ) : (
    <></>
  );
};

export default MediaPlaque;

import { Link, generatePath } from "react-router-dom";
import { Button } from "reactstrap";
import { IUser } from "@interfaces/Users/Users";
import { USERS_MODIFY } from "@constants/Routes";
import { ICurrentUser } from "@interfaces/Account/currentUser";
import { t } from "i18next";
import { UserRole } from "@enums/UserRole";

const listUsersMapper = (
  usersFromApi: IUser[],
  projectId: string,
  currentUser: ICurrentUser | null,
  handleDelete: Function,
  isUserAdmin: boolean,
  isUserOwner: boolean
) => {
  const usersMapped = usersFromApi.map(
    ({ id, name, role, address }, index) => ({
      order: index + 1,
      id,
      name,
      role,
      address,
      actions: (
        <>
          {(isUserOwner || (isUserAdmin && role !== UserRole.Owner)) && (
            <Link
              title={`${t("core:common.go_to")} ${t("core:common.edit")} ${t(
                "core:common.user"
              )}`}
              to={generatePath(USERS_MODIFY, {
                projectId: projectId,
                userId: id
              })}
            >
              <Button className="btn-sm btn-warning">
                <span className="lnr lnr-pencil" />
              </Button>
            </Link>
          )}

          {(isUserOwner || (isUserAdmin && role !== UserRole.Owner)) &&
            currentUser?.walletAddress !== address && (
              <Button
                title={`${t("core:common.delete")} ${t("core:common.user")}`}
                className="btn-sm  btn-danger"
                onClick={() => handleDelete(id, address, name, role)}
              >
                <span className="lnr lnr-trash" />
              </Button>
            )}
        </>
      )
    })
  );

  return usersMapped;
};

export default listUsersMapper;

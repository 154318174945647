import { t } from "i18next";
import emptyFieldValidate from "../shared/empty.validate";

export type TErrors = {
  message: string | undefined;
  whitelistedAddresses: string | undefined;
};

const WhiteListValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    message: undefined,
    whitelistedAddresses: undefined,
  };

  errors.message = emptyFieldValidate(
    FormValues.message,
    t("core:common:message")
  );

  errors.whitelistedAddresses = emptyFieldValidate(
    FormValues.whitelistedAddresses,
    t("core:common:whitelist")
  );

  return errors;
};

export default WhiteListValidate;

import { INameplatePreviewDto } from "@interfaces/Media/NameplatePreviewDto";
import apiInstance from "@services/Shared/instanceAxios";

const getNameplatePreview = ({
  mediaId,
  text,
  fontColor,
  backgroundColor,
  fontSize,
  fontFamily,
}: INameplatePreviewDto) =>
  apiInstance({
    url: `/media/${mediaId}/nameplate/preview/${text}`,
    params: fontSize
      ? { fontSize, fontFamily, fontColor, backgroundColor }
      : { fontFamily, fontColor, backgroundColor },
  });

export default getNameplatePreview;

import { useTranslation } from "react-i18next";
import { IColumn } from "@interfaces/Shared/column";

const ListUsersColumns = () => {
  const { t } = useTranslation("common");

  const columnsUsersTable: IColumn[] = [
    {
      Header: "#",
      accessor: "order",
      disableSortBy: true,
      disableGlobalFilter: true
    },
    {
      Header: t("core:common.name"),
      accessor: "name"
    },
    {
      Header: t("core:common.role"),
      accessor: "role"
    },
    {
      Header: t("core:common.wallet"),
      accessor: "address",
      disableGlobalFilter: true,
      disableSortBy: true
    },
    {
      Header: "",
      accessor: "actions",
      disableGlobalFilter: true,
      disableSortBy: true
    }
  ];

  return columnsUsersTable;
};

export default ListUsersColumns;

import axios from "axios";
import { API_URL } from "@constants/Env";

const getMessageToSign = () =>
  axios({
    baseURL: API_URL,
    url: "/message-to-sign",
  });

export default getMessageToSign;

import { ISelect } from "@interfaces/Shared/select";

export const GetFontFamily = (value: string) => {
  return GetFontFamilies().filter((p) => p.value === value)[0];
};

export const GetFontFamilies = (): ISelect[] => [
  { label: "Arial", value: "Arial" },
  { label: "Century", value: "Century" },
  { label: "Times New Roman", value: "Times New Roman" },
];

import { Web3ContextProvider } from "@nftstudios/web3-provider";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import {
  KEY_APPLOGO,
  KEY_APPNAME,
  KEY_INFURA_ID,
  KEY_WALLET_CONNECT_ID,
  KEY_WALLET_CONNECT_CHAIN,
} from "./constants/Env";
import { UserContextProvider } from "@context/UserContext/UserProvider";

ReactDOM.render(
  <React.StrictMode>
    <Web3ContextProvider
      infuraId={KEY_INFURA_ID}
      projectId={KEY_WALLET_CONNECT_ID}
      chainIds={[KEY_WALLET_CONNECT_CHAIN]}
      appName={KEY_APPNAME}
      appLogoUrl={KEY_APPLOGO}
      darkMode={false}
    >
      <UserContextProvider>
        <App />
      </UserContextProvider>
    </Web3ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

import { ReactElement } from "react";
import { Field, Form } from "react-final-form";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import useMediaUploaderMutator from "@hooks/Media/useMediaUploaderMutator";
import Error from "@components/Shared/Error/Error";
import BoxIcon from "@components/BoxIcon";
import Loader from "@components/Loader";
import Header from "../Header";
import styles from "./EditMedia.module.scss";
import FormVideo from "./components/FormVideo";
import FormImage from "./components/FormImage";
import editVideoValidations from "@validators/ArtUploader/EditVideo.validator";
import editImageValidations from "@validators/ArtUploader/EditImage.validator";
import { MediaType } from "@enums/mediaType";
import DropZone from "@components/DropZone";
import BoxToolbar from "../ButtonToolbar";
import { ArtUploadStep } from "@enums/artUploadStep";

interface IEditMedia {
  initialValues: IArtUploader;
  onFormSubmit: (values: IArtUploader) => void;
  onButtonCloseClicked: () => void;
  isLoading: boolean;
}

const EditMedia = ({
  initialValues,
  onFormSubmit,
  onButtonCloseClicked,
  isLoading,
}: IEditMedia): ReactElement => {
  const { t } = useTranslation();
  const { mediaMutator, selectProceed, selectCancel, isOpen } =
    useMediaUploaderMutator();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      validate={
        initialValues.mediaType === MediaType.video
          ? editVideoValidations
          : editImageValidations
      }
      mutators={mediaMutator}
    >
      {({ handleSubmit, values, form }) => {
        return (
          <Row>
            <form className={`form`} onSubmit={handleSubmit}>
              <Header
                title={`Step 2 - ${t("artuploader:title_edit_media")}`}
                form={form}
                isOpen={isOpen}
                handleConfirm={selectProceed}
                handleCancel={selectCancel}
              />
              <Col md={6}>
                {initialValues.mediaType === MediaType.video && (
                  <FormVideo
                    linkVideo={
                      (values?.dropzone && values?.dropzone[0]?.preview) ??
                      values.externalVideo
                    }
                  />
                )}

                {initialValues.mediaType === MediaType.image && (
                  <FormImage
                    linkImage={
                      (values?.dropzone && values.dropzone[0]?.preview) ??
                      values.externalImage
                    }
                  />
                )}
              </Col>
              <Col md={6}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("artuploader:image_link")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      component="input"
                      autoComplete="off"
                      name="onclick"
                      placeholder={t("artuploader:image_link")}
                    />
                    <BoxIcon
                      id="ButtonImageLink"
                      title={t("core:notifications.onclick_link_image")}
                    />
                    <Error name="onclick" />
                  </div>
                </div>
                {initialValues.mediaType === MediaType.video && (
                  <div className={styles.thumbnail}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {t("artuploader:thumbnail_title")}
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="thumbnail"
                          type="file"
                          component={({ input }) => (
                            <DropZone
                              {...input}
                              accept={{
                                "image/png": [".png"],
                                "image/jpeg": [".jpg"],
                              }}
                              description={t(
                                "artuploader:thumbnail_placeholder"
                              )}
                            />
                          )}
                        />

                        <Error name="thumbnail" />
                      </div>
                      <p>{t("artuploader:thumbnail_description")}</p>
                    </div>
                  </div>
                )}
              </Col>

              <BoxToolbar
                disabled={
                  initialValues.mediaType === MediaType.video &&
                  !values?.thumbnail?.length
                }
                mediaType={initialValues.mediaType}
                onClick={onButtonCloseClicked}
                artUploadStep={ArtUploadStep.EDIT_MEDIA_ASSET}
              />
            </form>
          </Row>
        );
      }}
    </Form>
  );
};

export default EditMedia;

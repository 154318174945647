import SidebarLink from "@sidebar/SidebarLink";
import { USERS, GATINGSYSTEM, SETTINGS } from "@constants/Routes";
import SidebarBuildingLayout from "../SidebarBuildingLayout";
import { ISidebarContent } from "@interfaces/Shared/Sidebar/Sidebar";
import { generatePath } from "react-router-dom";
import { useContext } from "react";
import UserContext from "@context/UserContext/UserContext";
import { useTranslation } from "react-i18next";
import { IsEnabledResource } from "@utils/security/resourcesAccess";
import { ResourcesAccess } from "@enums/ResourcesAccess";

const SidebarProject = ({ onClick, sidebarCollapse }: ISidebarContent) => {
  const { currentProject } = useContext(UserContext);
  const { t } = useTranslation(["core"]);

  return (
    <>
      <SidebarBuildingLayout
        onClick={onClick}
        sidebarCollapse={sidebarCollapse}
      />

      {IsEnabledResource(ResourcesAccess.OWNER_ADMIN) && (
        <SidebarLink
          title={t("core:common.users")}
          icon="users"
          route={generatePath(USERS, {
            projectId: currentProject?.project.id,
          })}
          onClick={onClick}
        />
      )}

      {IsEnabledResource(ResourcesAccess.GATINGSYSTEM) && (
        <SidebarLink
          title={t("core:common.gating_system")}
          icon="lock"
          route={generatePath(GATINGSYSTEM, {
            projectId: currentProject?.project.id,
          })}
          onClick={onClick}
        />
      )}

      {IsEnabledResource(ResourcesAccess.OWNER_ADMIN) && (
        <SidebarLink
          title={t("core:common.settings")}
          icon="cog"
          route={generatePath(SETTINGS, {
            projectId: currentProject?.project.id,
          })}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default SidebarProject;

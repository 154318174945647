import { IMAGE_FORMAT, VIDEO_FORMAT } from "@constants/Config";
import { MediaType } from "@enums/mediaType";

export const mediaTypeFromFile = (file: File): MediaType => {
  if (file.type === VIDEO_FORMAT) {
    return MediaType.video;
  } else if (IMAGE_FORMAT.includes(file.type)) {
    return MediaType.image;
  } else {
    return MediaType.none;
  }
};

import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import SidebarContent from "./SidebarContent";
import styles from "@pages/Layout/components/Sidebar/Sidebar.module.scss";

interface ISidebar {
  changeMobileSidebarVisibility: any;
  sidebarShow: boolean;
  sidebarCollapse: boolean;
}

const Sidebar = ({
  changeMobileSidebarVisibility,
  sidebarShow,
  sidebarCollapse
}: ISidebar) => {
  const sidebarClass = classNames({
    sidebar: true,
    "sidebar--show": sidebarShow,
    "sidebar--collapse": sidebarCollapse
  });

  return (
    <div className={sidebarClass}>
      <button
        type="button"
        aria-label="sidebar visibility"
        className="sidebar__back"
        onClick={changeMobileSidebarVisibility}
      />
      <Scrollbar className="sidebar__scroll scroll">
        <div
          className={`sidebar__wrapper sidebar__wrapper--desktop ${styles.sidebar__wrapper}`}
        >
          <SidebarContent
            onClick={() => {}}
            sidebarCollapse={sidebarCollapse}
          />
        </div>
        <div className="sidebar__wrapper sidebar__wrapper--mobile">
          <SidebarContent onClick={changeMobileSidebarVisibility} />
        </div>
      </Scrollbar>
    </div>
  );
};

export default Sidebar;

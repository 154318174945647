import Loader from "@components/Loader";
import ContainerCards from "@components/ContainerCards";
import Title from "@components/Title";
import useGetSpaces from "@hooks/Spaces/useGetSpaces";
import { useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import MediaCard from "@components/ContainerCards/components/MediaCard";

const Place = () => {
  const { projectId, spaceId, placeId } = useParams<{
    spaceId: string;
    placeId: string;
    projectId: string;
  }>();
  const { loading: isLoadingSpaces, data: responseSpaces } =
    useGetSpaces(projectId);
  const currentSpace = responseSpaces?.spaces.find(
    (space) => space.name === spaceId
  );
  const currentPlace = currentSpace?.places.find(
    (place) => place.name === placeId
  );

  if (isLoadingSpaces) {
    return <Loader />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title title={`${spaceId} - ${placeId}`} icon="apartment" />
        </Col>
      </Row>

      <ContainerCards key={placeId} isLoading={isLoadingSpaces} isPlaceCard>
        {currentPlace?.media?.map((placeMedia) => {
          return <MediaCard key={placeMedia.id} placeMedia={placeMedia} />;
        })}
      </ContainerCards>
    </Container>
  );
};

export default Place;

import { ReactElement } from "react";
import { Spinner } from "reactstrap";
import styles from "./Loader.module.scss";

interface ILoader {
  isInsideThing?: boolean;
  text?: string;
}

const Loader = ({ isInsideThing, text }: ILoader): ReactElement => {
  const loaderStyles = isInsideThing
    ? [styles.loader, styles.loader__insideThing]
    : [styles.loader];

  return (
    <div className={loaderStyles.join(" ")}>
      <Spinner animation="border" role="status" />
      {text && <span className={styles.loader__spinner__text}>{text}</span>}
    </div>
  );
};

export default Loader;

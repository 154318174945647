import { ButtonToolbar } from "reactstrap";
import EyeIcon from "mdi-react/EyeIcon";
import EyeOffIcon from "mdi-react/EyeOffIcon";
import styles from "./MediaButtonToolBar.module.scss";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import { Dispatch, SetStateAction, useState } from "react";
import MediaButton from "./components/MediaButton/MediaButton";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useHistory, useParams } from "react-router-dom";
import { showNotification } from "@components/EasyDev/Notification";
import deleteMedia from "@services/Media/deleteMedia";
import updateMedia from "@services/Media/updateMedia";
import { MEDIA_UPLOADER } from "@constants/Routes";
import { IMedia } from "@interfaces/Media/Media";
import { MediaType } from "@enums/mediaType";

interface IMediaButtonToolBar {
  placeMedia: IMedia;
  setMedia: Dispatch<SetStateAction<IMedia>>;
}

const MediaButtonToolBar = ({ placeMedia, setMedia }: IMediaButtonToolBar) => {
  const [modalDeleteStatus, setmodalDeleteStatus] = useState(false);
  const [modalShowHideStatus, setmodalShowHideStatus] = useState(false);
  const history = useHistory();
  const { t } = useTranslation("common");
  const { placeId, spaceId } = useParams<{
    spaceId: string;
    placeId: string;
  }>();

  const handleConfirmDelete = async () => {
    deleteMedia(placeMedia.id)
      .then(async () => {
        history.go(0);
      })
      .catch((error: { response: { data: { error: string } } }) => {
        showNotification(
          `${t("core:common.media")} ${t("core:common.delete")}`,
          `${t("core:notifications.err_message")} ${
            error?.response?.data?.error
          }`,
          t("core:common.danger")
        );
      })
      .finally(() => {
        handleModalDeleteStatus();
      });
  };

  const handleConfirmHideShowFrame = () => {
    updateMedia(placeMedia.id, {
      enabled: !placeMedia.enabled,
    })
      .then(async (response) => {
        setMedia({
          enabled: response.data.enabled,
          ...response.data,
        });
      })
      .catch((error) => {
        showNotification(
          `${t("core:common.media")}`,
          `${t("core:notifications.err_message")}`,
          t("core:common.danger")
        );
      })
      .finally(() => {
        handleModalShowHideStatus();
      });
  };

  const handleModalDeleteStatus = () => {
    setmodalDeleteStatus(!modalDeleteStatus);
  };

  const handleModalShowHideStatus = () => {
    setmodalShowHideStatus(!modalShowHideStatus);
  };

  return (
    <>
      <ModalConfirmation
        icon="trash"
        modalType="danger"
        title={`${t("core:common.media")} | ${t("core:common.delete_confirm")}`}
        body={`${t("core:notifications.delete_message")}?`}
        modalStatus={modalDeleteStatus}
        handleModalStatus={handleModalDeleteStatus}
        handleConfirm={handleConfirmDelete}
      />
      <ModalConfirmation
        icon="eye"
        modalType="warning"
        title={`${t("core:common.media")} | ${
          placeMedia.enabled
            ? t("core:common.hide_frame")
            : t("core:common.show_frame")
        }`}
        body={`${
          placeMedia.enabled
            ? t("core:notifications.hide_confirm")
            : t("core:notifications.show_confirm")
        }`}
        modalStatus={modalShowHideStatus}
        handleModalStatus={handleModalShowHideStatus}
        handleConfirm={handleConfirmHideShowFrame}
      />

      <ButtonToolbar className={styles.media__button__toolbar}>
        {placeMedia.imageUrl ||
        placeMedia.videoUrl ||
        placeMedia.type === MediaType.text ? (
          <>
            <Link
              to={generatePath(MEDIA_UPLOADER, {
                projectId: placeMedia.projectId,
                spaceId,
                placeId,
                mediaId: placeMedia.id,
              })}
            >
              <MediaButton
                id={`Edit${placeMedia.id}`}
                color="warning"
                tooltipText={t("core:tooltips.edit_icon")}
              >
                <span className="lnr lnr-pencil" />
              </MediaButton>
            </Link>
            <MediaButton
              onClick={handleModalShowHideStatus}
              id={`Show${placeMedia.id}`}
              color="primary"
              tooltipText={t("core:tooltips.show_icon")}
            >
              <span>{placeMedia.enabled ? <EyeIcon /> : <EyeOffIcon />}</span>
            </MediaButton>
            <MediaButton
              onClick={handleModalDeleteStatus}
              id={`Delete${placeMedia.id}`}
              color="danger"
              tooltipText={t("core:tooltips.delete_icon")}
            >
              <span className="lnr lnr-trash" />
            </MediaButton>
          </>
        ) : (
          <Link
            to={generatePath(MEDIA_UPLOADER, {
              projectId: placeMedia.projectId,
              spaceId,
              placeId,
              mediaId: placeMedia.id,
            })}
          >
            <MediaButton
              id={`Upload${placeMedia.id}`}
              color="success"
              tooltipText={t("core:tooltips.upload_icon")}
            >
              <span className="lnr lnr-upload" />
            </MediaButton>
          </Link>
        )}
      </ButtonToolbar>
    </>
  );
};

export default MediaButtonToolBar;

import { useContext, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Table from "@easydev/Tables/Table";
import UserContext from "@context/UserContext/UserContext";
import listProjectsMapper from "@mappers/Projects/Projects.mapper";
import Title from "@components/Title";
import { useTranslation } from "react-i18next";
import ProjectsColumns from "./Projects.columns";
import localStorageServices from "@utils/localStorage.services";
import { KEY_CURRENT_PROJECT } from "@constants/Env";

const Projects = () => {
  const { currentUser } = useContext(UserContext);
  const projects =
    currentUser?.projects && listProjectsMapper(currentUser.projects);
  const { t } = useTranslation("common");
  const columnsProjectsTable = ProjectsColumns();

  useEffect(() => {
    localStorageServices.removeLocalStorage(KEY_CURRENT_PROJECT);
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title title={t("core:common.projects")} icon="briefcase" />
        </Col>
      </Row>
      <Table columns={columnsProjectsTable} items={projects} />
    </Container>
  );
};

export default Projects;

import { IMAGE_FORMAT, VIDEO_FORMAT } from "@constants/Config";
import { t } from "i18next";

export const validateType = (typeToValidate: string, file: File) => {
  let result;

  switch (typeToValidate) {
    case "video":
      result =
        file.type !== VIDEO_FORMAT
          ? `${t("core:validators:video-wrong-type")}`.replace("XXX", file.type)
          : undefined;
      break;
    case "image":
      result = !IMAGE_FORMAT.includes(file.type)
        ? `${t("core:validators:image-wrong-type")}`.replace("XXX", file.type)
        : undefined;
      break;
  }

  return result;
};

import { ReactElement } from "react";
import { FormGroup, Row } from "reactstrap";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import fileUploadValidations from "@validators/ArtUploader/FileUpload.validator";
import useMediaUploaderMutator from "@hooks/Media/useMediaUploaderMutator";
import DropZone from "@components/DropZone";
import Loader from "@components/Loader";
import Error from "@components/Shared/Error/Error";
import Header from "../Header";
import styles from "./FileUpload.module.scss";
import BoxToolbar from "../ButtonToolbar";
import { ArtUploadStep } from "@enums/artUploadStep";

interface IFileUpload {
  initialValues: IArtUploader;
  onFormSubmit: (values: IArtUploader) => void;
  onButtonCloseClicked: () => void;
  isLoading: boolean;
}

const FileUpload = ({
  initialValues,
  onFormSubmit,
  onButtonCloseClicked,
  isLoading,
}: IFileUpload): ReactElement => {
  const { t } = useTranslation();
  const { mediaMutator, selectProceed, selectCancel, isOpen } =
    useMediaUploaderMutator();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.fileUpload}>
      <Form
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        validate={fileUploadValidations}
        mutators={mediaMutator}
      >
        {({ handleSubmit, values, form }) => {
          return (
            <form className={`form ${styles.form}`} onSubmit={handleSubmit}>
              <Row className={styles.row}>
                <Header
                  title={`Step 1 - ${t("artuploader:title_file_upload")}`}
                  subtitle={t("artuploader:subtitle_file_upload")}
                  description={t("artuploader:description_file_upload")}
                  form={form}
                  isOpen={isOpen}
                  handleConfirm={selectProceed}
                  handleCancel={selectCancel}
                />

                <FormGroup
                  className={`form__form-group-field form__form-group-input-wrap--error-above ${styles.formGroup}`}
                >
                  <Field
                    name="dropzone"
                    type="file"
                    component={(props) => (
                      <DropZone
                        {...props.input}
                        accept={{
                          "image/png": [".png"],
                          "image/jpeg": [".jpg"],
                          "video/mp4": [".mp4"],
                        }}
                        description={t("artuploader:dropzone_file_upload")}
                        disabled={!!values?.externalLink}
                      />
                    )}
                  />
                  <Error name="dropzone" />
                </FormGroup>
              </Row>

              <span className={styles.separator}>
                <hr /> {t("core:common.or")} <hr />
              </span>
              <div className={styles.containerLink}>
                <FormGroup
                  className={`form__form-group form__form-group-input-wrap--error-above ${styles.formGroup}`}
                >
                  <Field
                    className={`form__form-group-field ${styles.externalLink}`}
                    name="externalLink"
                    component="input"
                    type="text"
                    placeholder={`${t(
                      "artuploader:external_link"
                    )}: https://opensea.io/assets/...`}
                    disabled={values?.dropzone?.length}
                  />
                  <Error name="externalLink" />
                </FormGroup>
              </div>

              <BoxToolbar
                disabled={!values?.externalLink && !values?.dropzone?.length}
                mediaType={initialValues.mediaType}
                onClick={onButtonCloseClicked}
                artUploadStep={ArtUploadStep.SELECT_ASSET}
              />
            </form>
          );
        }}
      </Form>
    </div>
  );
};

export default FileUpload;

import { INftUserDto } from "@interfaces/GatingSystem/NftUserDto";

const updateNFTCollectorsMapper = (formValues: any): INftUserDto => {
  return {
    message: formValues.message,
    contractAddress: formValues.contractAddress,
    enabled: formValues.state,
  };
};

export default updateNFTCollectorsMapper;

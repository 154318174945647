import { ISelect } from "@interfaces/Shared/select";

const numbers = [
  3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  24, 25,
];

const sizes: ISelect[] = numbers.map((number) => {
  return { label: number.toString(), value: number.toString() };
});

sizes.push({ label: "Auto", value: "" });

export const GetFontSize = (value: string) => {
  return GetFontSizes().filter((p) => p.value === value)[0];
};

export const GetFontSizes = (): ISelect[] => sizes;

import { useEffect, useState } from "react";
import { IConfirmPromise } from "@interfaces/Shared/useConfirm";

const useConfirm = () => {
  const [needsCleanup, setNeedsCleanup] = useState(false);
  const [confirm, setConfirm] = useState<IConfirmPromise>({
    proceed: () => null,
    cancel: () => null,
    isOpen: false,
  });

  const isConfirmed = () => {
    setNeedsCleanup(true);

    const promise = new Promise((resolve, reject) => {
      setConfirm({
        proceed: resolve,
        cancel: reject,
        isOpen: true,
      });
    });

    return promise.then(
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return true;
      },
      () => {
        setConfirm({ ...confirm, isOpen: false });
        return false;
      }
    );
  };

  useEffect(() => {
    return () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    };
  }, [confirm, needsCleanup]);

  return {
    ...confirm,
    isConfirmed,
  };
};

export default useConfirm;

import { PLACE_DETAIL } from "@constants/Routes";
import { MediaType } from "@enums/mediaType";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link, useParams } from "react-router-dom";
import { Button, ButtonToolbar } from "reactstrap";
import styles from "./BoxToolbar.module.scss";
import {
  buttonBackText,
  buttonNextText,
  IButtonText,
} from "@helpers/buttonText";

interface IBoxToolbar {
  disabled: boolean;
  mediaType: MediaType;
  onClick: () => void;
  artUploadStep: keyof IButtonText;
}

const BoxToolbar = ({
  disabled,
  mediaType,
  onClick,
  artUploadStep,
}: IBoxToolbar): ReactElement => {
  const { t } = useTranslation();
  const { projectId, spaceId, placeId } = useParams<{
    projectId: string;
    spaceId: string;
    placeId: string;
  }>();

  return (
    <ButtonToolbar className={styles.buttonToolbar}>
      <Link
        to={generatePath(PLACE_DETAIL, {
          projectId,
          spaceId,
          placeId,
        })}
      >
        <Button onClick={onClick}>
          <span className="lnr lnr-cross-circle"></span>{" "}
          {t("core:common.cancel")}
        </Button>
      </Link>
      {mediaType !== MediaType.text && mediaType !== MediaType.none && (
        <Button onClick={onClick}>
          <span className="lnr lnr-arrow-left-circle"></span>{" "}
          {t(buttonBackText[artUploadStep])}
        </Button>
      )}
      <Button color="success" type="submit" disabled={disabled}>
        <span className="lnr lnr-arrow-right-circle"></span>{" "}
        {t(buttonNextText[artUploadStep])}
      </Button>
    </ButtonToolbar>
  );
};

export default BoxToolbar;

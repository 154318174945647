import { IWhitelistDto } from "@interfaces/GatingSystem/WhitelistDto";
import apiInstance from "@services/Shared/instanceAxios";

const updateWhiteList = (projectId: string, editDto: IWhitelistDto) =>
  apiInstance({
    url: `/projects/${projectId}/gating-system/whitelist`,
    method: "put",
    data: editDto,
  });

export default updateWhiteList;

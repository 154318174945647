import styles from "./MediaCard.module.scss";
import { IMedia } from "@interfaces/Media/Media";
import MediaButtonToolBar from "@components/ContainerCards/components/MediaCard/components/MediaButtonToolBar";
import MediaItem from "@components/ContainerCards/components/MediaCard/components/MediaItem";
import { useState } from "react";
import MediaBadge from "./components/MediaBadge";
import MediaPlaque from "./components/MediaPlaque";
import { MediaType } from "@enums/mediaType";

interface IMediaCard {
  placeMedia: IMedia;
}

const MediaCard = ({ placeMedia }: IMediaCard) => {
  const [media, setMedia] = useState<IMedia>(placeMedia);

  return (
    <div className={styles.media__card}>
      <MediaBadge index={media.order?.media} elementType={media.elementType} />
      {media.type !== MediaType.text && <MediaItem placeMedia={media} />}

      <MediaPlaque
        nameplate={media.nameplate}
        enabled={media.enabled}
        mediaType={media.type}
        videoPad={media.videoPad?.status}
      />

      <MediaButtonToolBar placeMedia={media} setMedia={setMedia} />
    </div>
  );
};

export default MediaCard;

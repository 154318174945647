import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FormVideo.module.scss";
import ReactPlayer from "react-player";

interface IFormVideo {
  linkVideo: string | undefined;
}

const FormVideo = ({ linkVideo }: IFormVideo): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.filePreview}>
        <ReactPlayer
          controls={true}
          volume={0.5}
          muted={true}
          loop={true}
          playing={false}
          url={linkVideo}
          className={styles.reactPlayer__video}
          width="292"
          height="232"
        />
        <p>{t("artuploader:video_preview")}</p>
      </div>
    </>
  );
};

export default FormVideo;

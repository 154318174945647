import UserContext from "@context/UserContext/UserContext";
import React, { useContext } from "react";
import SidebarLink from "@sidebar/SidebarLink";
import { LOGIN } from "@constants/Routes";
import { useTranslation } from "react-i18next";

const SidebarLogOut = () => {
  const { handleModalStatus } = useContext(UserContext);
  const { t } = useTranslation(["core"]);

  return (
    <SidebarLink
      route={LOGIN}
      title={t("core:navigation.log_out")}
      icon="exit"
      onClick={handleModalStatus}
    />
  );
};

export default SidebarLogOut;

import { ICreateEditUser } from "@interfaces/Users/ICreateEditUser";

const createUserMapper = (formValues: any): ICreateEditUser => {
  return {
    name: formValues.name,
    address: formValues.address,
    role: formValues.role.value,
  };
};

export default createUserMapper;

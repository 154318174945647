const textRowsValidate = (
  value: string,
  name: string,
  previous: string,
  setValue: any
) => {
  const rows = value.split(/\n/).length;

  if (rows > 2) {
    setValue(name, previous);
  }
};

export default textRowsValidate;

import { isValidURL } from "@validators/shared/url.validate";
import { t } from "i18next";

export type TErrors = {
  onclick: string | undefined;
};

const editImageValidations = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    onclick: undefined,
  };

  errors.onclick = isValidURL(
    FormValues.onclick,
    t("artuploader:onclick_link")
  );

  return errors;
};

export default editImageValidations;

import { IExtendedFile } from "@interfaces/Shared/Files/Files";
import apiInstance from "@services/Shared/instanceAxios";

const createMediaVideo = (mediaId: string, file: IExtendedFile) => {
  const data = new FormData();
  data.append("video", file);

  return apiInstance({
    url: `/media/${mediaId}/video`,
    method: "post",
    data,
  });
};

export default createMediaVideo;

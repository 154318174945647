import { INameplatePreviewDto } from "@interfaces/Media/NameplatePreviewDto";
import { defaultFontColor, defaultBackgroundColor } from "@constants/fontColor";

const nameplatePreviewMapper = (
  mediaId: string,
  text: string,
  fontColor?: string,
  backgroundColor?: string,
  fontSize?: string | undefined,
  fontFamily?: string | undefined
): INameplatePreviewDto => ({
  mediaId: mediaId,
  text: encodeURIComponent(text),
  fontColor: fontColor ?? defaultFontColor,
  backgroundColor: backgroundColor ?? defaultBackgroundColor,
  fontSize,
  fontFamily: fontFamily ?? "Century",
});

export default nameplatePreviewMapper;

import { ReactNode } from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

interface IMediaButton {
  id: string;
  color: string;
  children: ReactNode;
  tooltipText: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const MediaButton = ({
  id,
  children,
  onClick,
  color,
  tooltipText
}: IMediaButton) => {
  return (
    <>
      <Button id={`ButtonMedia${id}`} onClick={onClick} color={color}>
        {children}
      </Button>
      <UncontrolledTooltip
        trigger="hover"
        placement="bottom"
        target={`ButtonMedia${id}`}
      >
        {tooltipText}
      </UncontrolledTooltip>
    </>
  );
};

export default MediaButton;

import { ReactElement } from "react";
import { Field } from "react-final-form";
import styles from "./Error.module.scss";

interface IError {
  name: string;
}

const Error = ({ name }: IError): ReactElement => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <span className={`form__form-group-error ${styles.error}`}>
          {error}
        </span>
      ) : null
    }
  />
);

export default Error;

import { IColumn } from "@interfaces/Shared/column";
import { useTranslation } from "react-i18next";

const ProjectsColumns = () => {
  const { t } = useTranslation("common");

  const columnsProjectsTable: IColumn[] = [
    {
      Header: "#",
      accessor: "order",
      disableSortBy: true,
      disableGlobalFilter: true
    },
    {
      Header: t("core:common.name"),
      accessor: "name"
    },
    {
      Header: t("core:common.metaverse"),
      accessor: "metaverse",
      disableGlobalFilter: true
    },
    {
      Header: "",
      accessor: "actions",
      disableGlobalFilter: true,
      disableSortBy: true
    }
  ];

  return columnsProjectsTable;
};

export default ProjectsColumns;

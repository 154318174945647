import { t } from "i18next";
import emptyFieldValidate from "./empty.validate";

const userNameValidate = (userName: string) => {
  let errMsg = undefined;

  errMsg = emptyFieldValidate(userName, t("core:common.name"));

  if (userName) {
    if (userName.length < 3 || userName.length > 20) {
      errMsg = t("core:validators.username-characters");
    }
  }

  if (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(userName)) {
    errMsg = "Symbols are not allowed.";
  }

  return errMsg;
};

export default userNameValidate;

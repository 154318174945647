import { useEffect, useState } from "react";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import artUploaderMapper from "@mappers/ArtUploader/ArtUploader.mapper";
import createMediaImage from "@services/Media/createMediaImage";
import createMediaVideo from "@services/Media/createMediaVideo";
import useGetMedia from "./useGetMedia";
import deleteMediaVideo from "@services/Media/deleteMediaVideo";
import { generatePath, useHistory } from "react-router-dom";
import { PLACE_DETAIL } from "@constants/Routes";
import { createFile } from "@helpers/createFileFromUri";
import { VIDEO_FORMAT } from "@constants/Config";
import { ArtUploadStep } from "@enums/artUploadStep";
import { MediaType } from "@enums/mediaType";
import { defaultArtUploader } from "@mappers/ArtUploader/ArtUploader.default";
import useMediaDropzone from "@hooks/Media/useMediaDropzone";
import useMediaExternalLink from "@hooks/Media/useMediaExternalLink";
import updateMedia from "@services/Media/updateMedia";
import updateMediaMapper from "@mappers/Media/updateMediaMapper.mapper";
import useMediaMessage from "@hooks/Media/useMediaMessage";

const useMediaUploader = (
  projectId: string,
  spaceId: string,
  placeId: string,
  mediaId: string
) => {
  const history = useHistory();
  const [step, setStep] = useState<ArtUploadStep>(ArtUploadStep.SELECT_ASSET);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<IArtUploader>(defaultArtUploader);
  const { loading, data: response } = useGetMedia(mediaId);
  const { setDataExternalLink } = useMediaExternalLink(
    setFormData,
    setIsLoading
  );
  const { setDataDropzone } = useMediaDropzone(setFormData);
  const { SuccessMediaUpload, ErrorMediaUpload } = useMediaMessage();

  useEffect(() => {
    setIsLoading(loading);
    if (response && !loading) {
      artUploaderMapper(response).then((mappedData) => {
        setFormData(mappedData);
        setStep(mappedData.step);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, response]);

  const uploadData = (mediaId: string, artUploader: IArtUploader) => {
    return updateMedia(mediaId, updateMediaMapper(artUploader));
  };

  const submitFormData = async (artUploader: IArtUploader) => {
    try {
      setIsLoading(true);

      if (artUploader.dropzone && artUploader.dropzone.length > 0) {
        const type = artUploader.dropzone[0].type;

        if (type === VIDEO_FORMAT) {
          await createMediaVideo(mediaId, artUploader.dropzone[0]);

          if (artUploader.thumbnail && artUploader.thumbnail.length) {
            await createMediaImage(mediaId, artUploader.thumbnail[0]);
          }

          await uploadData(mediaId, artUploader);
          SuccessMediaUpload();
        } else {
          let file = artUploader.dropzone[0];
          file.preview = undefined;
          await createMediaImage(mediaId, file);
          await deleteMediaVideo(mediaId);
          await uploadData(mediaId, artUploader);
          SuccessMediaUpload();
        }
      } else if (artUploader.externalImage) {
        let file = await createFile(artUploader.externalImage);

        if (file) {
          file.preview = undefined;
          await createMediaImage(mediaId, file);
          await deleteMediaVideo(mediaId);
          await uploadData(mediaId, artUploader);
        }
      }

      if (artUploader.mediaType === MediaType.text) {
        await uploadData(mediaId, artUploader);
        SuccessMediaUpload();
      }
    } catch (error) {
      ErrorMediaUpload();
    } finally {
      setIsLoading(false);
      history.push(generatePath(PLACE_DETAIL, { projectId, spaceId, placeId }));
    }
  };

  const handleNextStep = async (artuploader: IArtUploader) => {
    if (artuploader.step === ArtUploadStep.SELECT_ASSET) {
      setIsLoading(true);
      if (artuploader.dropzone && artuploader.dropzone.length > 0) {
        await setDataDropzone(artuploader);
      } else if (artuploader.externalLink) {
        await setDataExternalLink(artuploader);
      }
      setIsLoading(false);
      setStep(artuploader.step);
    } else if (step === ArtUploadStep.EDIT_MEDIA_ASSET) {
      setFormData(artuploader);
      setStep(ArtUploadStep.EDIT_NAMEPLATE_ASSET);
    }
  };

  const handlePrevStep = () => {
    if (step === ArtUploadStep.EDIT_MEDIA_ASSET) {
      setFormData({
        dropzone: [],
        showFrame: true,
        externalLink: "",
        onclick: "",
        nameplateLink: null,
        nameplateImageUrl: null,
        thumbnail: [],
        mediaType: MediaType.none,
        step: ArtUploadStep.SELECT_ASSET,
      });
      setStep(ArtUploadStep.SELECT_ASSET);
    } else if (step === ArtUploadStep.EDIT_NAMEPLATE_ASSET) {
      setStep(ArtUploadStep.EDIT_MEDIA_ASSET);
    } else {
      history.push(generatePath(PLACE_DETAIL, { projectId, spaceId, placeId }));
    }
  };

  return {
    step,
    isLoading,
    formData,
    submitFormData,
    handleNextStep,
    handlePrevStep,
  };
};

export default useMediaUploader;

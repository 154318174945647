import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { ITabItem } from "@interfaces/Shared/TabItem";

interface ITabs {
  tabItems: ITabItem[];
}

const Tabs = ({ tabItems }: ITabs) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        {tabItems.map((item) => (
          <NavItem key={item.id}>
            <NavLink
              className={classnames({ active: activeTab === item.id })}
              onClick={() => toggle(item.id)}
            >
              {item.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabItems.map((item) => (
          <TabPane key={item.id} tabId={item.id}>
            {item.tab}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
};

export default Tabs;

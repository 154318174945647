import { VIDEO_FORMAT } from "@constants/Config";
import { MediaType } from "@enums/mediaType";
import { stepFromMediaType } from "@helpers/stepFromMediaType";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { Dispatch, SetStateAction } from "react";

const useMediaDropzone = (
  setFormData: Dispatch<SetStateAction<IArtUploader>>
) => {
  const setDataDropzone = async (artUploader: IArtUploader) => {
    if (artUploader.dropzone) {
      artUploader.mediaType =
        artUploader.dropzone[0]?.type === VIDEO_FORMAT
          ? MediaType.video
          : MediaType.image;
      artUploader.step = stepFromMediaType(artUploader.mediaType);
      setFormData(artUploader);
    }
  };

  return {
    setDataDropzone,
  };
};

export default useMediaDropzone;

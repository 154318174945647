import { translationItem } from "@interfaces/Shared/translationItem";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import translationLanguages from "../../translations/languages";
import storageService from "@utils/localStorage.services";

interface IUseLanguage {
  mainButtonContent: translationItem | undefined;
  setMainButtonContent: (translationItem: translationItem) => void;
  changeLanguage: (lng: string) => void;
  getLanguages: () => translationItem[];
}

export const UseLanguage = (): IUseLanguage => {
  const { i18n } = useTranslation("common");

  const [mainButtonContent, setMainButtonContent] = useState(
    translationLanguages.find(
      (item) => item.code === storageService.getI18nStorage()
    ) ?? translationLanguages.find((item) => item.default)
  );

  const searchLanguage = (lng: string) => {
    return translationLanguages.find((item) => item.code === lng);
  };

  const getLanguages = (): translationItem[] => {
    return translationLanguages;
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng).then(() => {
      setMainButtonContent(searchLanguage(lng));
      storageService.setI18nStorage(lng);
    });
  };

  return {
    mainButtonContent,
    setMainButtonContent,
    changeLanguage,
    getLanguages,
  };
};

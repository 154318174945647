import React, { MouseEventHandler, ReactElement } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import TopbarProfile from "./TopbarProfile";
import TopbarLanguage from "./TopbarLanguage";

interface ITopbar {
  changeMobileSidebarVisibility: MouseEventHandler<HTMLButtonElement>;
  changeSidebarVisibility: MouseEventHandler<HTMLButtonElement>;
}

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}: ITopbar): ReactElement => (
  <div className="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <TopbarSidebarButton
          onClickMobile={changeMobileSidebarVisibility}
          onClickDesktop={changeSidebarVisibility}
        />
        <Link className="topbar__logo" to="/" />
      </div>
      <div className="topbar__right">
        <TopbarLanguage />
        <TopbarProfile />
      </div>
    </div>
  </div>
);

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

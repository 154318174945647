import common from "../core/common.json";
import navigation from "../core/navigation.json";
import notifications from "../core/notifications.json";
import validators from "../core/validators.json";
import tooltips from "../core/tooltips.json";

const sources = {
  common,
  navigation,
  notifications,
  validators,
  tooltips,
};

export default sources;

import Alert from "@components/EasyDev/Alert";
import Loader from "@components/Loader";
import useGatingSystem from "@hooks/GatingSystem/useGatingSystem";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";

const AlertGatingSystem = (): ReactElement => {
  let textActiveTab;
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { gatingSystem, loading: isLoading } = useGatingSystem(projectId);
  const { t } = useTranslation("core");
  const dataTabs = [
    gatingSystem?.password,
    gatingSystem?.whitelist,
    gatingSystem?.contract,
  ];

  const enabledTab = dataTabs.map((tab) => {
    if (tab?.enabled === true) {
      return 1;
    }
    return 0;
  });

  switch (enabledTab.join(" ")) {
    case "1 0 0":
      textActiveTab = "Password";
      break;
    case "0 1 0":
      textActiveTab = "Whitelist";
      break;
    case "0 0 1":
      textActiveTab = "NFT Collectors";
      break;
  }

  if (isLoading) {
    return <Loader isInsideThing />;
  }

  return (
    <Col md={12} lg={12} xl={7}>
      <Row>
        <Card>
          <Alert color="warning" className="alert--bordered" icon>
            <span className="bold-text">Attention:</span>{" "}
            <div>
              <span className="bold-text underline-text">{textActiveTab}</span>{" "}
              {t("core:notifications.gating_system_alert")}
            </div>
          </Alert>
        </Card>
      </Row>
    </Col>
  );
};

export default AlertGatingSystem;

import axios, { AxiosRequestConfig } from "axios";
import { API_URL } from "@constants/Env";
import localStorageServices from "@utils/localStorage.services";

const defaultOptions = {
  baseURL: API_URL,
};

// Create instance
let apiInstance = axios.create(defaultOptions);

apiInstance.defaults.timeout = 120000;
apiInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const currentUser = localStorageServices.getLocalUserStorage();

  if (config.headers) {
    config.headers["auth-public-address"] = currentUser?.walletAddress ?? "";
    config.headers["auth-signature"] = currentUser?.signature ?? "";
  }

  return config;
});

export default apiInstance;

import styles from "./PreviewCard.module.scss";

interface IPreviewCard {
  url: string;
  alt: string;
  link?: string;
}

const PreviewCard = ({ url, alt, link }: IPreviewCard) => {
  return (
    <div className={styles.previewCard__container}>
      <a href={link ?? "#"} target="_blank" rel="noreferrer">
        <img src={url} alt={alt} />
      </a>
    </div>
  );
};

export default PreviewCard;

import { IUpdateSettings } from "@interfaces/Settings/UpdateSettingsURLs";
import apiInstance from "@services/Shared/instanceAxios";

const updateSettingsURLs = (projectId: string, data: IUpdateSettings) =>
  apiInstance({
    url: `/projects/${projectId}`,
    method: "put",
    data: data,
  });

export default updateSettingsURLs;

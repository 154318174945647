const getOpenSeaURLParams = (mediaURL: string) => {
  const regex = /0x([a-fA-F0-9]{40})\/(\d+)/;

  const matches = mediaURL.match(regex);

  if (matches && matches.length === 3) {
    const contractAddress = "0x" + matches[1];
    const tokenId = matches[2];
    return { contractAddress, tokenId };
  }
  return null;
};

export default getOpenSeaURLParams;

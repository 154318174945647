import { t } from "i18next";
import emptyFieldValidate from "../shared/empty.validate";

export type TErrors = {
  message: string | undefined;
  password: string | undefined;
};

const PasswordValidate = (FormValues: Record<string, any>) => {
  let errors: TErrors = {
    message: undefined,
    password: undefined,
  };

  errors.message = emptyFieldValidate(
    FormValues.message,
    t("core:common:message")
  );

  errors.password = emptyFieldValidate(
    FormValues.password,
    t("core:common:password")
  );

  return errors;
};

export default PasswordValidate;

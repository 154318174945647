import { useContext } from "react";
import UserContext from "@context/UserContext/UserContext";
import { ResourcesAccess } from "@enums/ResourcesAccess";
import { UserRole } from "@enums/UserRole";
import { IUser } from "@interfaces/Users/Users";

export const IsEnabledResource = (resource: ResourcesAccess): boolean => {
  const { currentUser, currentProject } = useContext(UserContext);

  const isEnabledByRole = (roleCondition: (user: IUser) => boolean) => {
    return (
      (currentProject?.project.users?.some(
        (user) =>
          roleCondition(user) && user.address === currentUser?.walletAddress
      ) ??
        false) ||
      (currentUser?.isSuperAdmin ?? false)
    );
  };

  switch (resource) {
    case ResourcesAccess.OWNER:
      return isEnabledByRole((user) => user.role === UserRole.Owner);
    case ResourcesAccess.ADMIN:
      return isEnabledByRole((user) => user.role === UserRole.Admin);
    case ResourcesAccess.OWNER_ADMIN:
      return isEnabledByRole((user) => user.role !== UserRole.Collaborator);
    case ResourcesAccess.GATINGSYSTEM:
      return (
        IsEnabledResource(ResourcesAccess.OWNER_ADMIN) &&
        (currentProject?.project.hasGatingSystem ?? false)
      );
  }
  return false;
};

import { validateSize } from "@validators/shared/fileSize.validator";
import { validateType } from "@validators/shared/fileType.validator";

export const validateVideo = (file: File) => {
  let message = validateSize(file);

  if (!message) {
    message = validateType("video", file);
  }

  return message;
};

import { IWhitelistDto } from "@interfaces/GatingSystem/WhitelistDto";

const updateWhiteListMapper = (formValues: any): IWhitelistDto => {
  return {
    message: formValues.message,
    whitelistedAddresses: formValues.whitelistedAddresses?.split("\n"),
    enabled: formValues.state,
  };
};

export default updateWhiteListMapper;

import { Link } from "react-router-dom";
import { PROJECTS } from "@constants/Routes";
import { Col, Container } from "reactstrap";

const NotFound404 = () => {
  return (
    <Container>
      <Col>
        <div className="not-found">
          <div className="not-found__content">
            <p className="code">404</p>
            <h3 className="not-found__info">
              Ooops! The page you are looking for could not be found :(
            </h3>
            <Link className="btn btn-primary" to={PROJECTS}>
              <p className="topbar__link-title">Back to projects</p>
            </Link>
          </div>
        </div>
      </Col>
    </Container>
  );
};

export default NotFound404;

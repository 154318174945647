import { IMediaUpdateDto } from "@interfaces/Media/MediaUpdateDto";
import apiInstance from "@services/Shared/instanceAxios";

const updateMedia = (mediaId: string, data: IMediaUpdateDto) =>
  apiInstance({
    url: `/media/${mediaId}`,
    method: "put",
    data: data,
  });

export default updateMedia;

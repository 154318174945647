import styles from "./MediaDefault.module.scss";
import ImageOutlineIcon from "mdi-react/ImageOutlineIcon";
import { useContext } from "react";
import UserContext from "@context/UserContext/UserContext";
import MediaImage from "../MediaImage";

const MediaDefault = () => {
  const { currentProject } = useContext(UserContext);

  return currentProject?.project.defaultImage ? (
    <MediaImage
      imageUrl={currentProject?.project.defaultImage}
      link={currentProject?.project.defaultLink}
    />
  ) : (
    <div className={styles.mediaItem__add}>
      <ImageOutlineIcon />
    </div>
  );
};

export default MediaDefault;

import { Field, Form } from "react-final-form";
import { Link, generatePath } from "react-router-dom";
import { Button, ButtonToolbar, Col } from "reactstrap";
import AddLink from "mdi-react/LinkIcon";
import ImageIcon from "mdi-react/ImageIcon";
import FormField from "@easydev/form/FormField";
import { useTranslation } from "react-i18next";
import { PROJECTS_DETAIL } from "@constants/Routes";
import styles from "./SettingsForm.module.scss";
import Loader from "@components/Loader";
import settingsValidate from "@validators/Settings/Settings.validator";
import useGetProject from "@hooks/Project/useGetProject";
import PreviewCard from "@components/ContainerCards/components/PreviewCard";

interface ISettingsForm {
  isLoading: boolean;
  projectId: string;
  setFormValues: any;
  handleModalStatus: () => void;
}

const SettingsForm = ({
  isLoading,
  projectId,
  setFormValues,
  handleModalStatus,
}: ISettingsForm) => {
  const { t } = useTranslation("common");
  const { loading: isLoadingProject, data: responseProject } =
    useGetProject(projectId);

  const urlsInitialValues = {
    defaultImage: responseProject?.defaultImage,
    defaultLink: responseProject?.defaultLink,
  };

  if (isLoadingProject) {
    return <Loader isInsideThing />;
  }

  return (
    <Form
      onSubmit={(FormValues) => {
        setFormValues(FormValues);
        handleModalStatus();
      }}
      validate={settingsValidate}
      initialValues={urlsInitialValues}
    >
      {({ handleSubmit, form }) => (
        <form className="form" onSubmit={handleSubmit}>
          <Col md={6}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("core:common.default_image")}
              </span>
              <div
                className="form__form-group-field"
                style={{ alignContent: "start" }}
              >
                <div className="form__form-group-icon">
                  <ImageIcon />
                </div>
                <Field
                  name="defaultImage"
                  component={FormField as any}
                  type="text"
                  placeholder="http://..."
                  isAboveError
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {t("core:common.default_link")}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AddLink />
                </div>
                <Field
                  name="defaultLink"
                  component={FormField as any}
                  type="text"
                  placeholder="http://..."
                  isAboveError
                />
              </div>
            </div>
          </Col>
          {urlsInitialValues.defaultImage && (
            <Col md={6}>
              <PreviewCard
                url={urlsInitialValues.defaultImage}
                alt="default image preview"
                link={urlsInitialValues.defaultLink}
              />
            </Col>
          )}
          <ButtonToolbar
            className={`form__button-toolbar ${styles.button__toolbar} ${
              isLoading && `${styles.loader}`
            }`}
          >
            {isLoading ? (
              <Loader isInsideThing />
            ) : (
              <>
                <Link to={generatePath(PROJECTS_DETAIL, { projectId })}>
                  <Button type="button" onClick={form.reset}>
                    <span className={`lnr lnr-chevron-left ${styles.lnr}`} />
                    {t("core:common.goback")}
                  </Button>
                </Link>
                <Button color="success" type="submit">
                  <span className={`lnr lnr-pencil ${styles.lnr}`} />
                  {t("core:common.save")}
                </Button>
              </>
            )}
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

export default SettingsForm;

import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { ArtUploadStep } from "@enums/artUploadStep";
import useMediaUploader from "@hooks/Media/useMediaUploader";
import FileUpload from "./components/FileUpload";
import Title from "@components/Title";
import EditMedia from "./components/EditMedia";
import Loader from "@components/Loader";
import EditNameplate from "./components/EditNameplate";

const ArtUploader = (): ReactElement => {
  const { projectId, spaceId, placeId, mediaId } = useParams<{
    projectId: string;
    spaceId: string;
    placeId: string;
    mediaId: string;
  }>();
  const {
    step,
    formData,
    submitFormData,
    handleNextStep,
    handlePrevStep,
    isLoading,
  } = useMediaUploader(projectId, spaceId, placeId, mediaId);

  const handleFormSubmit = (values: IArtUploader) => {
    submitFormData(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Title title={`${spaceId} - ${placeId}`} icon="apartment" />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              <Col md={12} xl={12} lg={12} xs={12}>
                {step === ArtUploadStep.SELECT_ASSET && (
                  <FileUpload
                    initialValues={formData}
                    onFormSubmit={handleNextStep}
                    onButtonCloseClicked={handlePrevStep}
                    isLoading={isLoading}
                  />
                )}

                {step === ArtUploadStep.EDIT_MEDIA_ASSET && (
                  <EditMedia
                    initialValues={formData}
                    onFormSubmit={handleNextStep}
                    onButtonCloseClicked={handlePrevStep}
                    isLoading={isLoading}
                  />
                )}

                {step === ArtUploadStep.EDIT_NAMEPLATE_ASSET && (
                  <EditNameplate
                    initialValues={formData}
                    onFormSubmit={handleFormSubmit}
                    onButtonCloseClicked={handlePrevStep}
                    isLoading={isLoading}
                  />
                )}
              </Col>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ArtUploader;

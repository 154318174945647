import { MediaType } from "@enums/mediaType";
import { createFile } from "@helpers/createFileFromUri";
import { mediaTypeFromFile } from "@helpers/mediaTypeFromFile";
import { stepFromMediaType } from "@helpers/stepFromMediaType";
import { IArtUploader } from "@interfaces/ArtUploader/ArtUploader";
import { validateDataLink } from "@validators/ArtUploader/ExternalLink.validator";
import { validateImage } from "@validators/ArtUploader/FileImage.validator";
import { validateVideo } from "@validators/ArtUploader/FileVideo.validator";
import {
  isOpenSeaURL,
  isValidOpenSeaChain,
} from "@validators/shared/url.validate";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import useMediaMessage from "@hooks/Media/useMediaMessage";
import getOpenSeaURLParams from "@helpers/getOpenSeaURLParams";
import getMetadataService from "@services/Media/getMetadataService";

const useMediaExternalLink = (
  setFormData: Dispatch<SetStateAction<IArtUploader>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>
) => {
  const { t } = useTranslation();
  const { ErrorMediaUpload } = useMediaMessage();

  const setDataExternalLink = async (artUploader: IArtUploader) => {
    if (artUploader.externalLink) {
      const isOpenSeaUrl = isOpenSeaURL(artUploader.externalLink);

      if (isOpenSeaUrl) {
        const validateChainOpenSea = isValidOpenSeaChain(
          artUploader.externalLink
        );

        if (validateChainOpenSea) {
          ErrorMediaUpload(validateChainOpenSea);
          setIsLoading(false);
          return;
        }

        await setDataOpenSeaLink(artUploader);
      } else {
        await setDataGeneralLink(artUploader);
      }
    }
  };

  const setDataOpenSeaLink = async (artUploader: IArtUploader) => {
    if (artUploader.externalLink) {
      const openSeaParams = getOpenSeaURLParams(artUploader.externalLink);
      if (openSeaParams === null) return;
      const { contractAddress, tokenId } = openSeaParams;
      const { data: asset } = await getMetadataService(
        contractAddress,
        tokenId
      );

      const validateMessage = validateDataLink(asset);
      if (validateMessage) {
        ErrorMediaUpload(validateMessage);
        setIsLoading(false);
        return;
      }

      artUploader.externalLink = asset.external_url;
      artUploader.onclick = asset.external_url;
      artUploader.nameplateText = asset.name;
      artUploader.externalImage = asset.ipfs_image_url
        ? asset.ipfs_image_url
        : asset.image_url;
      artUploader.externalVideo = asset.animation_url;
      artUploader.mediaType = MediaType.image;

      if (artUploader.externalImage) {
        const imageFile = await createFile(artUploader.externalImage);

        if (imageFile === undefined) {
          ErrorMediaUpload(t("core:notifications.err_image_url"));
          setIsLoading(false);
          return;
        }

        if (imageFile) {
          const validateMessage = validateImage(imageFile);

          if (validateMessage) {
            ErrorMediaUpload(validateMessage);
            setIsLoading(false);
            return;
          }
        }

        artUploader.thumbnail = imageFile ? [imageFile] : [];
      }

      if (asset.animation_url) {
        let videoFile = await createFile(asset.animation_url);

        if (videoFile) {
          const validateMessage = validateVideo(videoFile);
          if (validateMessage) {
            ErrorMediaUpload(validateMessage);
            setIsLoading(false);
            return;
          }
        }

        artUploader.mediaType = MediaType.video;
        artUploader.dropzone = videoFile ? [videoFile] : [];
      }

      artUploader.step = stepFromMediaType(artUploader.mediaType);

      setFormData(artUploader);
    }
  };

  const setDataGeneralLink = async (artUploader: IArtUploader) => {
    if (artUploader.externalLink) {
      const generalFile = await createFile(artUploader.externalLink);

      artUploader.onclick = undefined;
      artUploader.externalVideo = undefined;
      artUploader.externalImage = undefined;

      if (!generalFile) {
        ErrorMediaUpload(t("core:validators:invalid-data-url"));
        setIsLoading(false);
        return;
      }

      if (generalFile) {
        const mediaType = mediaTypeFromFile(generalFile);

        if (mediaType === MediaType.none) {
          ErrorMediaUpload(t("core:validators:valid-type-url"));
          setIsLoading(false);
          return;
        }

        artUploader.mediaType = mediaType;
        artUploader.step = stepFromMediaType(mediaType);

        if (mediaType === MediaType.image) {
          if (generalFile) {
            const validateMessage = validateImage(generalFile);
            if (validateMessage) {
              ErrorMediaUpload(validateMessage);
              setIsLoading(false);
              return;
            }
          }

          artUploader.externalImage = artUploader.externalLink;
          artUploader.thumbnail = generalFile ? [generalFile] : [];
        } else if (mediaType === MediaType.video) {
          if (generalFile) {
            const validateMessage = validateVideo(generalFile);
            if (validateMessage) {
              ErrorMediaUpload(validateMessage);
              setIsLoading(false);
              return;
            }
          }

          artUploader.externalVideo = artUploader.externalLink;
          artUploader.dropzone = generalFile ? [generalFile] : [];
        }
      }

      setFormData(artUploader);
    }
  };

  return {
    setDataExternalLink,
  };
};

export default useMediaExternalLink;

import nameplatePreviewMapper from "@mappers/Media/getNamepletePreview.mapper";
import getNameplatePreview from "@services/Media/getNameplatePreview";
import { useState, useEffect } from "react";

interface IUseGetNameplatePreview {
  isLoading: boolean;
  nameplateText: string | undefined;
  nameplatePreview: string | undefined;
  setNameplateText: (nameplateText: string | undefined) => void;
  setNameplateFontSize: (nameplateText: string | undefined) => void;
  setNameplateFontFamily: (nameplateText: string | undefined) => void;
  setNameplateFontColor: (fontColor: string | undefined) => void;
  setNameplateBackgroundColor: (backgroundColor: string | undefined) => void;
}

const useGetNameplatePreview = (mediaId: string): IUseGetNameplatePreview => {
  const [nameplateText, setNameplateText] = useState<string | undefined>(
    undefined
  );
  const [nameplateFontSize, setNameplateFontSize] = useState<
    string | undefined
  >(undefined);
  const [nameplateFontFamily, setNameplateFontFamily] = useState<
    string | undefined
  >(undefined);
  const [nameplateFontColor, setNameplateFontColor] = useState<
    string | undefined
  >(undefined);
  const [nameplateBackgroundColor, setNameplateBackgroundColor] = useState<
    string | undefined
  >(undefined);
  const [nameplatePreview, setNameplatePreview] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (nameplateText) {
      setIsLoading(true);
      const mapper = nameplatePreviewMapper(
        mediaId,
        nameplateText,
        nameplateFontColor,
        nameplateBackgroundColor,
        nameplateFontSize,
        nameplateFontFamily
      );

      getNameplatePreview(mapper)
        .then(async (response) => {
          setNameplatePreview(response.data.image);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    mediaId,
    nameplateText,
    nameplateFontSize,
    nameplateFontFamily,
    nameplateFontColor,
    nameplateBackgroundColor,
  ]);

  return {
    isLoading,
    nameplateText,
    setNameplateText,
    setNameplateFontSize,
    setNameplateFontFamily,
    setNameplateFontColor,
    setNameplateBackgroundColor,
    nameplatePreview,
  };
};

export default useGetNameplatePreview;

export interface IButtonText {
  SELECT_ASSET: string;
  EDIT_IMAGE_ASSET: string;
  EDIT_TEXT_ASSET: string;
}

export const buttonBackText: IButtonText = {
  SELECT_ASSET: "",
  EDIT_IMAGE_ASSET: "artuploader:modify_media_upload",
  EDIT_TEXT_ASSET: "artuploader:edit_media_info",
};

export const buttonNextText: IButtonText = {
  SELECT_ASSET: "artuploader:edit_media_info",
  EDIT_IMAGE_ASSET: "artuploader:edit_nameplate",
  EDIT_TEXT_ASSET: "core:common.save",
};

import { showNotification } from "@components/EasyDev/Notification";
import ModalConfirmation from "@components/Shared/Modals/ModalConfirmation";
import showHideAllMedia from "@services/Media/showHideAllMedia";
import { t } from "i18next";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ButtonToolbar } from "reactstrap";
import MediaButton from "../MediaCard/components/MediaButtonToolBar/components/MediaButton";

const MediaShowHideToolbar = () => {
  const [modalShowAllStatus, setModalShowAllStatus] = useState(false);
  const [modalHideAllStatus, setModalHideAllStatus] = useState(false);

  const history = useHistory();

  const { placeId, spaceId, projectId } = useParams<{
    spaceId: string;
    placeId: string;
    projectId: string;
  }>();

  const handleShowHideAllMedia = async (hide: boolean) => {
    const values = {
      section: spaceId,
      subsection: placeId,
      ...(hide ? { enabled: false } : { enabled: true }),
    };
    showHideAllMedia(projectId, values)
      .then(async () => {
        history.go(0);
      })
      .catch((error: { response: { data: { error: string } } }) => {
        showNotification(
          `${t("core:notifications.err_message")} ${
            error?.response?.data?.error
          }`,
          t("core:common.danger"),
        );
      })
      .finally(() => {
        if (hide) {
          handleModalHideAllStatus();
        } else {
          handleModalShowAllStatus();
        }
      });
  };

  const handleModalShowAllStatus = () => {
    setModalShowAllStatus(!modalShowAllStatus);
  };

  const handleModalHideAllStatus = () => {
    setModalHideAllStatus(!modalHideAllStatus);
  };

  return (
    <>
      <ModalConfirmation
        icon="flag"
        modalType="warning"
        title={`${t("core:common.media")} | ${t("core:common.hide_all_frame")}`}
        body={`${t("core:notifications.hide_all_frames")}`}
        modalStatus={modalHideAllStatus}
        handleModalStatus={handleModalHideAllStatus}
        handleConfirm={() => handleShowHideAllMedia(true)}
      />
      <ModalConfirmation
        icon="flag"
        modalType="warning"
        title={`${t("core:common.media")} | ${t("core:common.show_all_frame")}`}
        body={`${t("core:notifications.show_all_frames")}`}
        modalStatus={modalShowAllStatus}
        handleModalStatus={handleModalShowAllStatus}
        handleConfirm={() => handleShowHideAllMedia(false)}
      />

      <ButtonToolbar className="form__button-toolbar justify-content-end">
        <MediaButton
          id="hideAll"
          color="danger"
          tooltipText={t("core:tooltips.hide_all")}
          onClick={handleModalHideAllStatus}
        >
          {`${t("core:common.hide_all_frame")}`}
        </MediaButton>

        <MediaButton
          id="showAll"
          color="primary"
          tooltipText={t("core:tooltips.show_all")}
          onClick={handleModalShowAllStatus}
        >
          {`${t("core:common.show_all_frame")}`}
        </MediaButton>
      </ButtonToolbar>
    </>
  );
};

export default MediaShowHideToolbar;

import { ReactElement } from "react";
import { Link } from "react-router-dom";

interface ITopbarMenuLinks {
  title: string;
  icon: string;
  path: string;
  onclick?: () => void;
}

const TopbarMenuLinks = ({
  title,
  icon,
  path,
  onclick,
}: ITopbarMenuLinks): ReactElement => (
  <Link className="topbar__link" to={path} onClick={onclick}>
    <span className={`topbar__link-icon lnr lnr-${icon}`} />
    <p className="topbar__link-title">{title}</p>
  </Link>
);

export default TopbarMenuLinks;

import { ISidebarContent } from "@interfaces/Shared/Sidebar/Sidebar";
import UserContext from "@context/UserContext/UserContext";
import SidebarCategory from "@sidebar/SidebarCategory";
import { ReactElement, useContext } from "react";
import { PLACE_DETAIL, PROJECTS_DETAIL, SPACE_DETAIL } from "@constants/Routes";
import SidebarLink from "@sidebar/SidebarLink";
import { generatePath, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isExactMatch } from "@helpers/exactMatch";

const SidebarBuildingLayout = ({
  sidebarCollapse,
  onClick,
}: ISidebarContent): ReactElement => {
  const { currentProject } = useContext(UserContext);
  const { t } = useTranslation(["core"]);
  const location = useLocation();

  const isBuildingLayoutPath = !!matchPath(location.pathname, {
    path: [PROJECTS_DETAIL, SPACE_DETAIL, PLACE_DETAIL],
    strict: false,
    exact: true,
  });

  return currentProject ? (
    <SidebarCategory
      title={t("core:common.building_layout")}
      icon="apartment"
      sidebarCollapse={isBuildingLayoutPath}
      isBuildingLayoutPath={isBuildingLayoutPath}
    >
      <>
        {currentProject.spaces?.map((space) => (
          <SidebarCategory
            key={space.name}
            title={space.name}
            sidebarCollapse={sidebarCollapse}
            isSubmenuActive={
              isExactMatch(location.pathname.toString(), space.name)
                ? true
                : false
            }
          >
            <>
              {space.places.map((place) => (
                <SidebarLink
                  key={place.name}
                  title={place.name}
                  route={generatePath(PLACE_DETAIL, {
                    projectId: currentProject.project.id,
                    projectName: encodeURIComponent(
                      currentProject.project.name
                    ),
                    spaceId: encodeURIComponent(space.name),
                    placeId: encodeURIComponent(place.name),
                  })}
                  onClick={onClick}
                />
              ))}
            </>
          </SidebarCategory>
        ))}
      </>
    </SidebarCategory>
  ) : (
    <></>
  );
};

export default SidebarBuildingLayout;

import { ICreateEditUser } from "@interfaces/Users/ICreateEditUser";
import apiInstance from "@services/Shared/instanceAxios";

const createUser = (projectId: string, newUser: ICreateEditUser) =>
  apiInstance({
    url: `/projects/${projectId}/users/`,
    method: "post",
    data: newUser,
  });

export default createUser;

import { useContext } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import MainWrapper from "./MainWrapper";
import UserContext from "@context/UserContext/UserContext";
import { HOME, LOGIN, PROJECTS } from "@constants/Routes";
import LogIn from "@pages/LogIn/index";
import NotFound404 from "@pages/NotFound404";
import WrappedRoutes from "./WrappedRoutes";

const Router = () => {
  const { authenticated } = useContext(UserContext);
  const location = useLocation();

  if (location.pathname !== LOGIN && !authenticated) {
    return <Redirect to={LOGIN} />;
  }

  if (
    (location.pathname === LOGIN && authenticated) ||
    location.pathname === HOME
  )
    return <Redirect to={PROJECTS} />;

  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path={LOGIN} component={LogIn} />
          <Route path={PROJECTS} component={WrappedRoutes} />
          <Route component={NotFound404} />
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;

import Loader from "@components/Loader";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Field, Form } from "react-final-form";
import { Col, Button, Card, CardBody, ButtonToolbar } from "reactstrap";
import { showNotification } from "@easydev/Notification";
import PasswordField from "@easydev/form/Password";
import { useTranslation } from "react-i18next";
import updatePasswordMapper from "@mappers/GatingSystem/updatePasswordMapper.mapper";
import updatePassword from "@services/GatingSystem/updatePassword";
import PasswordValidate from "@validators/GatingSystem/Password.validate";
import Switch from "@components/Switch";
import BoxIcon from "@components/BoxIcon";
import AlertGatingSystem from "./AlertGatingSystem";
import useGatingSystem from "@hooks/GatingSystem/useGatingSystem";

const TabPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation("core");
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const { gatingSystem, updateGatingSystem, loading } =
    useGatingSystem(projectId);

  const handleEditPassword = (FormValues: Record<string, any>) => {
    setIsLoading(true);
    const gatingDto = updatePasswordMapper(FormValues);

    updatePassword(projectId, gatingDto)
      .then(async () => {
        if (gatingSystem) {
          gatingSystem.password = gatingDto;
          updateGatingSystem(gatingSystem);
        }
      })
      .catch((error) => {
        showNotification(
          `${t("core:common.gating_system")} | ${t("core:common.password")}`,
          `${t("core:notifications.err_message")} ${
            error?.response?.data?.error
          }`,
          t("core:common.danger")
        );
      })
      .finally(() => {
        setIsLoading(false);

        showNotification(
          `${t("core:common.gating_system")} | ${t("core:common.password")}`,
          t("core:notifications.update_success_message"),
          t("core:common.success")
        );
        window.location.reload();
      });
  };

  if (loading) {
    return <Loader isInsideThing />;
  }

  if (gatingSystem?.contract.enabled || gatingSystem?.whitelist.enabled) {
    return <AlertGatingSystem />;
  }

  return (
    <Col xs={12} md={12} lg={12} xl={8}>
      <Card>
        <CardBody>
          <Form
            onSubmit={(FormValues) => {
              handleEditPassword(FormValues);
            }}
            validate={PasswordValidate}
          >
            {({ handleSubmit, values }) => (
              <form className="form form--horizontal" onSubmit={handleSubmit}>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("core:common.state")}
                  </span>
                  <div className="form__form-group-field">
                    <Switch
                      name="state"
                      leftText={t("core:common.disabled")}
                      rightText={t("core:common.enabled")}
                      checked={gatingSystem?.password.enabled}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("core:common.message")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="message"
                      component="textarea"
                      maxLength={100}
                      initialValue={gatingSystem?.password.message}
                      disabled={!values?.state}
                      type="text"
                      isAboveError
                    />
                    <BoxIcon
                      id="ButtonPassword"
                      title={t("core:notifications.information-icon")}
                    />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {t("core:common.password")}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="password"
                      disabled={!values?.state}
                      component={PasswordField as any}
                      maxLength={10}
                      initialValue={gatingSystem?.password.password}
                      isAboveError
                    />
                  </div>
                </div>
                {isLoading ? (
                  <Loader isInsideThing />
                ) : (
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="success" type="submit">
                      <span className="lnr lnr-pencil"></span>{" "}
                      {t("core:common.save")}
                    </Button>
                  </ButtonToolbar>
                )}
              </form>
            )}
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default TabPassword;
